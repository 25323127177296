import CommonListItem from 'components/CommonListItem'
import { prompt } from 'components/Prompt'
import IMSSettingsDialog from 'components/Segment/IMSSettingsDialog'
import { showSnackbar } from 'components/Snackbar'
import SplitButton from 'components/SplitButton'
import useSegment from 'hooks/useSegment'
import { httpsCallable } from 'lib/functions'
import { addDoc, getDocs, updateDoc, where } from 'lib/firestore'
import { download } from 'utils'

export default function StaticSegmentListItem({
  item,
  group,
  editable,
  onClick,
  onDelete,
  onDuplicate,
}) {
  const { items } = useSegment()
  const hasDependencies = items.some(x => x.condition.filters.some(y => item.id === y.segment?.id))

  const handleOpenLink = () => {
    const { projectId, datasetId, tableId } = item.result.table.tableReference
    const qs = Object.entries({
      project: projectId,
      p: projectId,
      d: datasetId,
      t: tableId,
      page: 'table',
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&')
    const url = `https://console.cloud.google.com/bigquery?${qs}`
    window.open(url, '_blank')
  }

  const handleDownload = () => {
    return httpsCallable('callable-segment-createsignedurl')({ table: item.result.table })
      .then(res => download(res.data.url))
      .catch(e => showSnackbar(`ダウンロードに失敗しました。${e.message}`))
  }

  const handleExportToIMS = async () => {
    const docs = await getDocs(
      'ims-jobs',
      where('state', '==', 'PENDING'),
      where('segment.id', '==', item.id)
    )
    let data = {}
    if (docs.length > 0) {
      data = docs[0]
    }

    const settings = await prompt(IMSSettingsDialog, data)
    if (!settings) {
      return
    }

    if (data.id) {
      return updateDoc(`ims-jobs/${data.id}`, { ...settings, segment: item, state: 'PENDING' })
        .then(() => showSnackbar('IMS 連携ジョブの登録に成功しました'))
        .catch(e => showSnackbar(`連携に失敗しました。${e.message}`))
    } else {
      return addDoc('ims-jobs', { ...settings, segment: item, state: 'PENDING' })
        .then(() => showSnackbar('IMS 連携ジョブの登録に成功しました'))
        .catch(e => showSnackbar(`連携に失敗しました。${e.message}`))
    }
  }

  const options = [{ text: '結果を見る', onClick: handleOpenLink }]
  if (item.idType === 'serial') {
    options.push({
      text: 'ダウンロード',
      onClick: handleDownload,
      disabled: item.idType !== 'serial',
    })
    options.push({
      text: 'IMS に連携',
      onClick: handleExportToIMS,
      disabled: item.idType !== 'serial',
    })
  }

  return (
    <CommonListItem
      item={item}
      group={group}
      editable={editable && !hasDependencies}
      actionSecondary={<SplitButton disabled={!item.result} options={options} />}
      onClick={onClick}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
    />
  )
}
