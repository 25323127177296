import { useEffect, useMemo, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { MEMBER_ROLE } from 'entities/group'

import { useDocs } from 'lib/firestore'

export default function MemberDialog({ members, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const { items } = useDocs('users')
  const [checked, setChecked] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleSubmit = () => {
    onSubmit(
      checked.map(x => ({
        id: x.id,
        email: x.email,
        role: MEMBER_ROLE.EDITOR,
      }))
    )
    onClose()
  }

  const handleChange = (member, checked) => {
    if (checked) {
      setChecked(v => [...v, member])
    } else {
      setChecked(v => v.filter(x => x.id !== member.id))
    }
  }

  const handleChangeSearchText = v => {
    setSearchText(v)
  }

  const filteredItems = useMemo(() => {
    return (items || [])
      .filter(x => !members.some(y => y.id === x.id)) // 既存メンバーを表示しない
      .filter(x => x.email.match(searchText)) // 検索条件にマッチしたユーザーのみ表示
  }, [items, searchText, members])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <DialogTitle>メンバー追加</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            placeholder="検索..."
            onChange={e => handleChangeSearchText(e.target.value)}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => handleChangeSearchText('')}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <List
            sx={{
              '& .MuiListItem-root:not(:last-child)': {
                borderBottom: theme => `1px solid ${theme.palette.divider}`,
              },
            }}
          >
            {filteredItems.map(x => (
              <MemberListItem
                key={x.id}
                member={x}
                checked={checked.some(y => y.id === x.id)}
                onChange={handleChange}
              />
            ))}
          </List>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          キャンセル
        </Button>
        <Button color="inherit" sx={{ fontWeight: 'bold' }} onClick={handleSubmit}>
          追加する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const MemberListItem = ({ member, checked, onChange }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => onChange(member, !checked)} dense>
        <ListItemIcon>
          <Checkbox edge="start" checked={checked} disableRipple />
        </ListItemIcon>
        <ListItemText primary={member.email} />
      </ListItemButton>
    </ListItem>
  )
}
