import { useState } from 'react'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { USER_STATUS_LABEL } from 'entities/user'

import { showSnackbar } from 'components/Snackbar'
import { updateDoc, useDocs } from 'lib/firestore'

import UserListItem from './ListItem'

export default function UserList() {
  const [role, setRole] = useState('*')
  const { items } = useDocs('users')
  const filteredItems = items.filter(x => role === '*' || x.status === role)

  const handleChange = (item, values) => {
    updateDoc(`users/${item.id}`, values).catch(error => {
      console.log(error)
      showSnackbar('ユーザー情報の更新に失敗しました')
    })
  }

  return (
    <>
      <Box>
        <Select
          value={role}
          sx={{
            mb: 1,
            minWidth: 120,
            '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
          }}
          onChange={e => setRole(e.target.value)}
        >
          <MenuItem value="*">すべて</MenuItem>
          {Object.entries(USER_STATUS_LABEL).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 240 }}>Email</TableCell>
              <TableCell>権限</TableCell>
              <TableCell>最終アクセス日時</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map(x => (
              <UserListItem key={x.id} item={x} onChange={handleChange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
