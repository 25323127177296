import { createContext, useContext, useEffect, useState } from 'react'

import { addDoc, useDoc } from 'lib/firestore'

const JobContext = createContext()

const JOB_STATUS = Object.freeze({
  WAITING_JOB: 'WAITING_JOB', // BigQuery の job 完了待ち
  PENDING: 'PENDING', // 処理待ち
  RUNNING: 'RUNNING', // 処理中
  DONE: 'DONE', // 完了
  ERROR: 'ERROR', // エラー
})

export const JOB_MODE = Object.freeze({
  SAVE: 'SAVE', // セグメントを実行し、BigQuery のテーブルと GCS ( CSV 形式 ) に結果を保存
  PREVIEW: 'PREVIEW', // セグメントのプレビュー
  BREAKDOWN: 'BREAKDOWN', // 内訳機能
})

export function JobProvider({ children }) {
  const [job, setJob] = useState(null)
  const { item } = useDoc(`jobs/${job?.id}`)
  const running = job && (job.id !== item?.id || !['DONE', 'ERROR'].includes(item?.state))

  useEffect(() => {
    if (!item) return
    if (item.id !== job.id) return
    switch (item.state) {
      case 'DONE':
        job.resolve(item)
        break
      case 'ERROR':
        job.reject(item.error)
        break
    }
  }, [item, job])

  const registerJob = async data => {
    return addDoc('jobs', { state: JOB_STATUS.PENDING, ...data }).then(doc => {
      return new Promise((resolve, reject) => {
        setJob({ id: doc.id, resolve, reject })
      })
    })
  }

  return <JobContext.Provider value={{ job: item, running, registerJob }} children={children} />
}

export default function useJob() {
  return useContext(JobContext)
}
