import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'

import { Form, SubmitButton, TextField } from 'components/Form'
import useAuth from 'lib/auth'
import { addDoc, updateDoc } from 'lib/firestore'

export default function IPDialog({ data, onClose }) {
  const [open, setOpen] = useState(false)
  const { user } = useAuth()
  const form = useForm({ defaultValues: data })

  useEffect(() => {
    setOpen(true)
  }, [data])

  const onUpdate = values => {
    updateDoc(`ips/${data.id}`, { ...values })
      .then(onClose)
      .catch(error => form.setError('network', { message: error.message }))
  }

  const onSubmit = async values => {
    addDoc('ips', { ...values, createdBy: user.id, deleted: false })
      .then(onClose)
      .catch(error => form.setError('network', { message: error.message }))
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Form form={form} onSubmit={data.id ? onUpdate : onSubmit}>
        <DialogTitle>IP ホワイトリスト設定</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextField label="表示名" name="name" rules={{ required: true }} fullWidth autoFocus />
            <TextField label="IP アドレス" name="ip" rules={{ required: true }} fullWidth />
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
            設定する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
