import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import { Select, TextField } from 'components/Form'

const operators = [
  { label: 'と一致 (=)', value: '=' },
  { label: 'より大きい (>)', value: '>' },
  { label: '以上 (>=)', value: '>=' },
  { label: 'より小さい (<)', value: '<' },
  { label: '以下 (<=)', value: '<=' },
]

export default function AggregateField({ name, table }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const { watch, setValue } = useFormContext()
  const _name = `${name}.aggregate`
  const values = watch(_name)

  useEffect(() => {
    if (values && Object.keys(values).length > 0) {
      setOpen(true)
    }
  }, [values])

  const handleRemove = () => {
    setOpen(false)
    setValue(_name, {})
    setAnchorEl(null)
  }

  return (
    <Box sx={{ mt: 2 }}>
      {open ? (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ fontWeight: 'bold', width: 80 }}>集計指標</Typography>
          <Select
            name={`${_name}.column`}
            label="集計対象"
            rules={{ required: true }}
            sx={{ flex: 8 }}
          >
            {table.config.aggregations.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <Typography sx={{ mx: -0.5 }}>が</Typography>
          <TextField
            name={`${_name}.value`}
            label="値"
            type="number"
            sx={{ flex: 4 }}
            defaultValue=""
          />
          <Select
            name={`${_name}.operator`}
            label="演算子"
            rules={{ required: true }}
            sx={{ flex: 3 }}
            defaultValue=">="
          >
            {operators.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)} sx={{ mt: '4px' }}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <List sx={{ minWidth: 80 }}>
              <ListItem disablePadding>
                <ListItemButton onClick={handleRemove}>
                  <ListItemText primary="削除" />
                </ListItemButton>
              </ListItem>
            </List>
          </Popover>
        </Box>
      ) : (
        <Button variant="text" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          集計指標を追加
        </Button>
      )}
    </Box>
  )
}
