import { grey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'

const primary = '#003e70'

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: '#007adf',
    },
    text: {
      primary: grey[800],
      secondary: grey[600],
    },
    background: {
      default: grey[50],
      paper: '#fff',
    },
    divider: grey[300],
  },
  drawer: { width: 240 },
  spacing: 8,
  typography: {
    h2: { fontSize: 32, fontWeight: 'bold' },
    body1: { fontSize: 14 },
    caption: { fontSize: 12 },
    button: { textTransform: 'none' },
    fontFamily: [
      '"Hiragino Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          background: grey[50],
          color: primary,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: grey[200],
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSnackbar: {
      defaultProps: {
        open: false,
        autoHideDuration: 3000,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        text: {
          color: '#007adf',
        },
        outlined: {
          color: '#007adf',
          borderColor: '#007adf',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          background: '#FF0C3E',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        disableEscapeKeyDown: true,
      },
      styleOverrides: {
        paper: {
          minWidth: 600,
        },
      },
    },
    MuiFab: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: {
          position: 'fixed',
          bottom: 16,
          right: 16,
        },
      },
    },
  },
})

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
