import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import dayjs from 'dayjs'
import { USER_STATUS, USER_STATUS_LABEL } from 'entities/user'

import useAuth from 'lib/auth'

export default function UserListItem({ item, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { user } = useAuth()

  const handleGrantAdmin = () => {
    setAnchorEl(null)
    onChange(item, { status: USER_STATUS.ADMIN })
  }

  const handleGrantUser = () => {
    setAnchorEl(null)
    onChange(item, { status: USER_STATUS.USER })
  }

  const handleDelete = () => {
    setAnchorEl(null)
    onChange(item, { status: USER_STATUS.DELETED })
  }

  const ActionList = () => {
    switch (item.status) {
      case USER_STATUS.ADMIN:
        return (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGrantUser()}>
                <ListItemText primary="ユーザー権限にする" />
              </ListItemButton>
            </ListItem>
          </List>
        )

      case USER_STATUS.USER:
        return (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGrantAdmin()}>
                <ListItemText primary="管理者権限にする" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDelete()}>
                <ListItemText primary="削除する" />
              </ListItemButton>
            </ListItem>
          </List>
        )

      case USER_STATUS.PENDING:
        return (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGrantUser()}>
                <ListItemText primary="承認する" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDelete()}>
                <ListItemText primary="許可しない" />
              </ListItemButton>
            </ListItem>
          </List>
        )

      case USER_STATUS.DELETED:
        return (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGrantUser()}>
                <ListItemText primary="ユーザー権限にする" />
              </ListItemButton>
            </ListItem>
          </List>
        )
    }
  }

  return (
    <>
      <TableRow sx={{ '&:last-child .MuiTableCell-root': { border: 0 } }}>
        <TableCell sx={{ width: 240 }}>{item.email}</TableCell>
        <TableCell>{USER_STATUS_LABEL[item.status]}</TableCell>
        <TableCell>
          {Boolean(item.lastLoginAt) ? dayjs(item.lastLoginAt).format('YYYY-MM-DD HH:mm') : ''}
        </TableCell>
        <TableCell align="right" sx={{ py: 1 }}>
          {user.isAdmin && item.id !== user.id && (
            <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ActionList />
      </Popover>
    </>
  )
}
