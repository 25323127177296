import { useFormContext } from 'react-hook-form'

import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Autocomplete, Select, TextField } from 'components/Form'
import useEventTrigger from 'hooks/useEventTrigger'
import { isNaturalNumber } from 'utils'

export const defaultCondition = Object.freeze({
  field: 'count',
  value: '',
  operator: '>=',
})

const operators = Object.freeze([
  { label: 'と等しい', value: '=' },
  { label: '未満', value: '<' },
  { label: '以下', value: '<=' },
  { label: '以上', value: '>=' },
  { label: 'より大きい', value: '>' },
])

const durationUnits = Object.freeze([
  { label: '時間', value: 'hour', seconds: 60 * 60 },
  { label: '日間', value: 'day', seconds: 24 * 60 * 60 },
  { label: '週間', value: 'week', seconds: 7 * 24 * 60 * 60 },
])
// TTL ポリシーが 30 日なので、それを超える期間は指定できない
const DURATION_LIMIT = 30 * 24 * 60 * 60

export default function ConditionField({ name, ...props }) {
  const { watch, getValues } = useFormContext()
  const eventTrigger = watch(`${name}.eventTrigger`)
  const { items: eventTriggers } = useEventTrigger()

  // TODO: カウントなのか種類なのか
  // TODO: イベントトリガーの集計指標を複数扱える前提で構築する
  return (
    <Stack {...props} direction="row" spacing={2} sx={{ alignItems: 'center' }}>
      <Autocomplete
        label="イベント名"
        name={`${name}.eventTrigger`}
        fullWidth
        sx={{ flex: 2 }}
        freeSolo
        rules={{ required: true }}
        optionKeys={['name', 'description']}
        autoSelect={false}
        options={eventTriggers}
      />
      <Typography>の</Typography>
      <Select
        name={`${name}.field`}
        label="集計フィールド"
        fullWidth
        rules={{ required: true }}
        sx={{ flex: 2 }}
        defaultValue="match"
      >
        <MenuItem value="count">イベント数</MenuItem>
        {eventTrigger?.aggregation.fields?.map(({ name, description }) => (
          <MenuItem key={name} value={name}>
            {description || name} のユニーク数
          </MenuItem>
        ))}
      </Select>
      <Typography>が</Typography>
      <TextField
        name={`${name}.duration.value`}
        type="number"
        label="集計期間"
        sx={{ flex: 1 }}
        rules={{
          required: true,
          validate: value => {
            if (!isNaturalNumber(value)) {
              return '集計期間は正の整数で指定してください'
            }
            const unit = getValues(`${name}.duration.unit`)
            const seconds = durationUnits.find(({ value }) => value === unit)?.seconds
            return value * seconds > DURATION_LIMIT
              ? '集計期間は 30 日以内で指定してください'
              : true
          },
        }}
      />
      <Select name={`${name}.duration.unit`} sx={{ flex: 1 }} rules={{ required: true }}>
        {durationUnits.map(({ label, value }) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Typography>以内に</Typography>
      <TextField
        name={`${name}.value`}
        type="number"
        label="値"
        sx={{ flex: 1 }}
        rules={{ required: true }}
      />
      <Typography>回</Typography>
      <Select
        name={`${name}.operator`}
        label="演算子"
        rules={{ required: true }}
        sx={{ flex: 1 }}
        defaultValue="match"
      >
        {operators.map(({ label, value, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
