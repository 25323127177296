import { useFieldArray, useFormContext } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { aggregateFieldOptions } from 'entities/eventTrigger'

import { Autocomplete } from 'components/Form'

import MoreVertButton from './MoreVertButton'

const defaultAggregation = { path: '', description: '' }

export default function AggregationField({ name }) {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({ control, name })

  return (
    <Stack spacing={2}>
      {fields.map((item, index) => (
        <Box key={item.id} sx={{ display: 'flex', gap: 1 }}>
          <Autocomplete
            label="パス"
            name={`${name}.${index}`}
            fullWidth
            sx={{ flex: 1 }}
            freeSolo
            rules={{ required: true }}
            optionKeys={['name', 'description']}
            autoSelect={false}
            options={aggregateFieldOptions}
          />
          <Box>
            <MoreVertButton options={[{ label: '削除', onClick: () => remove(index) }]} />
          </Box>
        </Box>
      ))}
      <Box>
        <Button
          variant="text"
          disabled={fields.length >= 5}
          startIcon={<AddIcon />}
          onClick={() => append(defaultAggregation)}
        >
          集計フィールドの追加
        </Button>
      </Box>
    </Stack>
  )
}
