import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { Form, SubmitButton, TextField } from 'components/Form'
import Loading from 'components/Loading'
import { getSignInInfo, isSignInWithEmailLink, signInWithEmailLink } from 'lib/auth'

export default function FinishSignUp() {
  const navigate = useNavigate()
  const { email, url } = getSignInInfo()
  const form = useForm()

  useEffect(() => {
    if (email) {
      signInWithEmailLink(email)
        .then(() => navigate(url || '/'))
        .catch(error => {
          console.log(error)
          form.setError('network', error)
        })
    }
  }, [navigate, email, form, url])

  const onSubmit = ({ email }) => {
    return signInWithEmailLink(email)
      .then(() => navigate(url || '/'))
      .catch(error => {
        console.log(error.message)
        form.setError('email', { type: error.code, message: error.message })
      })
  }

  if (!isSignInWithEmailLink(window.location.href)) {
    navigate('/login', { replace: true })
  }

  if (form.formState.errors.network) {
    return (
      <Box>
        <Typography variant="h2" sx={{ mb: 5 }}>
          認証に失敗しました
        </Typography>
        <Typography sx={{ mb: 5 }}>
          <>
            認証に失敗しました。
            <br />
            大変お手数をおかけしますが再度ログイン画面からお試しください。
          </>
        </Typography>
        <Button variant="text" onClick={() => navigate('login')}>
          ログイン画面に移動
        </Button>
      </Box>
    )
  }

  if (email) {
    return <Loading />
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        ログイン
      </Typography>
      <Typography sx={{ mb: 4 }}>
        ログインリンクで使用したメールアドレスを入力してください。
      </Typography>
      <TextField
        label="メールアドレス"
        name="email"
        rules={{
          required: true,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'メールアドレスの形式が不正です',
          },
        }}
        fullWidth
        sx={{ mb: 2 }}
      />
      <SubmitButton>ログイン</SubmitButton>
    </Form>
  )
}
