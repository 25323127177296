import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

export default function CommonListItem({
  item,
  group,
  editable,
  actionSecondary,
  onClick,
  onDelete,
  onDuplicate,
}) {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <Box
        sx={{
          mb: 2,
          p: 2,
          bgcolor: '#fff',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <ButtonBase
          onClick={() => {
            onClick()
            setAnchorEl(null)
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: 1,
            flex: 1,
            textAlign: 'left',
          }}
          centerRipple={true}
        >
          <Typography variant="caption" color="text.secondary">
            {group?.name || '個人'}
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{item.name}</Typography>
          {item.tags?.length > 0 && (
            <Box>
              {item.tags.map((tag, i) => (
                <Chip
                  key={i}
                  label={tag}
                  sx={{ mr: '6px', fontSize: 10, height: 24, '& .MuiChip-label': { px: 1 } }}
                />
              ))}
            </Box>
          )}
          {item.memo && (
            <Typography variant="caption" color="text.secondary">
              {item.memo}
            </Typography>
          )}
        </ButtonBase>
        {actionSecondary}
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 120 }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                onDuplicate()
                setAnchorEl(null)
              }}
            >
              <ListItemText primary="コピーして作成" />
            </ListItemButton>
          </ListItem>
          {editable && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  onDelete()
                  setAnchorEl(null)
                }}
              >
                <ListItemText primary="削除" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  )
}
