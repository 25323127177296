import { createContext, useContext } from 'react'

import { useDocs, where } from 'lib/firestore'

const SegmentContext = createContext()

export function SegmentProvider({ children }) {
  const { items } = useDocs('segments', where('deleted', '==', false))

  return <SegmentContext.Provider value={{ items }} children={children} />
}

export default function useSegment() {
  return useContext(SegmentContext)
}
