import FilterAltIcon from '@mui/icons-material/FilterAltTwoTone'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import useSharedData from 'hooks/useSharedData'

export const filter = (items, tags) => {
  if (tags.length === 0) return items
  return items.filter(item => {
    return tags.some(tag => item.tags.includes(tag))
  })
}

export default function TagFilter({ value, onChange }) {
  const sharedData = useSharedData()

  return (
    <Autocomplete
      multiple
      limitTags={2}
      options={sharedData?.tags || []}
      value={value}
      renderInput={params => <TextField {...params} label="タグ" />}
      popupIcon={<FilterAltIcon sx={{ fontSize: 16 }} />}
      disableClearable
      onChange={(_, v) => onChange(v)}
      sx={{
        minWidth: 180,
        maxWidth: 560,
        '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
        },
        '& .MuiAutocomplete-popupIndicator': { mt: '4px' },
        '& .MuiAutocomplete-popupIndicatorOpen': { transform: 'none' },
      }}
    />
  )
}
