export const qs = search => {
  let obj = {}
  search
    .slice(1)
    .split('&')
    .filter(x => x !== '')
    .forEach(x => {
      const [key, value] = x.split('=')
      obj[key] = decodeURIComponent(value)
    })
  return obj
}

export const numberWithCammas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const download = url => {
  let link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadCSV = (fileName, rows) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + rows.map(x => x.join(',')).join('\n')
  )
  element.setAttribute('download', fileName)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const findParam = (url, key) => {
  const params = url.split('?')[1]
  if (!params) return null
  const match = params
    .split('&')
    .map(x => x.split('='))
    .find(([k]) => k === key)
  return Boolean(match) ? match[1] : null
}

export const isNaturalNumber = value => {
  if (typeof value === 'string') {
    const number = parseInt(value)
    return number.toString() === value && number >= 0
  } else if (typeof value === 'number') {
    return parseInt(value) === value && value >= 0
  } else {
    return false
  }
}
