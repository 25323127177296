import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'

export default function AddButton({ options, children }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <Button variant="text" startIcon={<AddIcon />} onClick={handleClick}>
        {children}
      </Button>
      <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <List sx={{ minWidth: 80 }}>
          {options.map((x, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  x.onClick()
                  setAnchorEl(null)
                }}
              >
                <ListItemText primary={x.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}
