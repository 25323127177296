export const conditionFieldOptions = [
  { name: 'ingest.action', description: 'イベントの種類（ユーザーの操作）' },
  { name: 'ingest.category', description: 'イベントの対象（操作が何に対して行われたか）' },
  { name: 'ingest.context.content_id', description: 'コンテンツ ID' },
  { name: 'ingest.context.content_name', description: 'コンテンツ名' },
  { name: 'ingest.context.custom_object', description: 'カスタムオブジェクト' },
  { name: 'ingest.context.page_title', description: 'ページタイトル' },
  { name: 'ingest.context.product_family', description: 'プロダクト' },
  { name: 'enriched.url.pathname', description: 'ページのパス' },
]

export const aggregateFieldOptions = [
  { name: 'ingest.context.content_id', description: 'コンテンツ ID' },
  { name: 'ingest.context.content_name', description: 'コンテンツ名' },
  { name: 'ingest.context.page_title', description: 'ページタイトル' },
  { name: 'enriched.url.pathname', description: 'ページのパス' },
]
