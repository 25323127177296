import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import CodeIcon from '@mui/icons-material/CodeTwoTone'
import CopyIcon from '@mui/icons-material/ContentCopy'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { v4 as uuidv4 } from 'uuid'

import { showSnackbar } from 'components/Snackbar'

import ContentEditorDialog, { defaultValues } from './ContentEditorDialog'

const defaultValue = defaultValues['text/html']
export const defaultContents = [
  {
    id: uuidv4(),
    name: 'バリエーション 1',
    subject: '',
    body: defaultValue,
  },
]

const calculateRatio = (proportion, index, numOfContents) => {
  if (proportion > 100) {
    proportion = 100
  }

  if (index === 0) {
    return proportion + '%'
  }

  return Math.round(((100 - proportion) / (numOfContents - 1)) * 10) / 10 + '%'
}

export default function ContentFieldArray({ name, control, disabled }) {
  const [targetVariation, setTargetVariation] = useState(null)
  const [editingIndex, setEditingIndex] = useState(-1)
  const { fields, append, insert, remove, update } = useFieldArray({ control, name })
  const { watch } = useFormContext()
  const [contents, proportion] = watch(['contents', 'proportion'])

  const handleClickEditor = i => {
    setEditingIndex(i)
  }

  const handleAddVariation = () => {
    append({
      name: `バリエーション ${fields.length + 1}`,
      id: uuidv4(),
      subject: '',
      body: defaultValue,
    })
  }

  const handleChangeVariation = (index, values) => {
    update(index, values)
  }

  const handleRemoveVariation = () => {
    if (fields.length > 1) {
      remove(targetVariation.index)
    }
    setTargetVariation(null)
  }

  const handleDuplicateVariation = () => {
    const values = fields[targetVariation.index]
    console.log(values)
    insert(targetVariation.index + 1, { ...values, name: `${values.name} のコピー`, id: uuidv4() })
    setTargetVariation(null)
  }

  const handleClickId = id => {
    navigator.clipboard.writeText(id).then(
      () => {
        showSnackbar('コピーしました')
      },
      () => {
        showSnackbar('コピーに失敗しました')
      }
    )
  }

  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 2,
          border: theme => `1px solid ${theme.palette.grey[500]}`,
          borderRadius: 2,
          background: theme => theme.palette.grey[100],
          '& > .MuiBox-root:not(:last-child)': {
            borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
          },
        }}
      >
        {contents.map((item, i) => (
          <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', py: 1, gap: 2 }}>
            <Typography sx={{ width: 48 }}>
              {calculateRatio(proportion, i, contents.length)}
            </Typography>
            <Typography>{item.name}</Typography>
            <Tooltip title="Copy" placement="right">
              <Button
                variant="text"
                sx={{ color: 'text.secondary', fontSize: 12 }}
                endIcon={<CopyIcon />}
                onClick={() => handleClickId(item.id)}
              >
                ID: {item.id}
              </Button>
            </Tooltip>
            <Box sx={{ flex: 1, color: 'red' }} />
            <Button variant="text" onClick={() => handleClickEditor(i)} startIcon={<CodeIcon />}>
              エディタを開く
            </Button>
            <IconButton
              onClick={e => setTargetVariation({ index: i, element: e.currentTarget })}
              disabled={disabled}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        ))}
        <Box sx={{ py: 1 }}>
          <Button
            variant="text"
            onClick={handleAddVariation}
            disabled={fields.length >= 10 || disabled}
          >
            バリエーションを追加
          </Button>
        </Box>
        <Popover
          open={Boolean(targetVariation)}
          anchorEl={Boolean(targetVariation) && targetVariation.element}
          onClose={() => setTargetVariation(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List sx={{ minWidth: 80 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDuplicateVariation()}>
                <ListItemText primary="複製" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleRemoveVariation()} disabled={fields.length <= 1}>
                <ListItemText primary="削除" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>
      {editingIndex >= 0 && (
        <ContentEditorDialog
          language="text/html"
          disabled={disabled}
          defaultValue={fields[editingIndex]}
          onChange={values => handleChangeVariation(editingIndex, values)}
          onClose={() => setEditingIndex(-1)}
        />
      )}
    </>
  )
}
