import { hasBatchQuery } from 'entities/segment'

import CommonListItem from 'components/CommonListItem'
import { prompt } from 'components/Prompt'
import IMSSettingsDialog from 'components/Segment/IMSSettingsDialog'
import { showSnackbar } from 'components/Snackbar'
import SplitButton from 'components/SplitButton'
import useJob, { JOB_MODE, JobProvider } from 'hooks/useJob'
import useSegment from 'hooks/useSegment'
import { httpsCallable } from 'lib/functions'
import { addDoc, getDocs, updateDoc, where } from 'lib/firestore'
import { download } from 'utils'

function DynamicSegmentListItem({ item, group, editable, onClick, onDelete, onDuplicate }) {
  const { registerJob } = useJob()
  const { items } = useSegment()
  const hasDependencies = items.some(x => x.condition.filters.some(y => item.id === y.segment?.id))

  const handleDownload = () => {
    if (hasBatchQuery(item)) {
      showSnackbar(
        '実行に時間がかかる条件が含まれています。処理が完了するまでしばらくお待ち下さい。',
        { autoHideDuration: 5000 }
      )
    }

    return httpsCallable('callable-ip-validate')()
      .then(() => registerJob({ segment: item, mode: JOB_MODE.SAVE }))
      .then(job => download(job.result.url))
      .catch(error => {
        console.error(error)
        showSnackbar(`クエリの実行に失敗しました。${error.message}`)
      })
  }

  const handleExportToIMS = async () => {
    const docs = await getDocs(
      'ims-jobs',
      where('state', '==', 'PENDING'),
      where('segment.id', '==', item.id)
    )
    let data = {}
    if (docs.length > 0) {
      data = docs[0]
    }

    const settings = await prompt(IMSSettingsDialog, data)
    if (!settings) {
      return
    }

    if (data.id) {
      return updateDoc(`ims-jobs/${data.id}`, { ...settings, segment: item, state: 'PENDING' })
        .then(() => showSnackbar('IMS 連携ジョブの登録に成功しました'))
        .catch(e => showSnackbar(`連携に失敗しました。${e.message}`))
    } else {
      return addDoc('ims-jobs', { ...settings, segment: item, state: 'PENDING' })
        .then(() => showSnackbar('IMS 連携ジョブの登録に成功しました'))
        .catch(e => showSnackbar(`連携に失敗しました。${e.message}`))
    }
  }

  const options = []
  if (item.idType === 'serial') {
    options.push({ text: 'ダウンロード', onClick: handleDownload })
    options.push({ text: 'IMS に連携', onClick: handleExportToIMS })
  }

  return (
    <CommonListItem
      item={item}
      group={group}
      editable={editable && !hasDependencies}
      actionSecondary={options.length > 0 ? <SplitButton options={options} /> : null}
      onClick={onClick}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
    />
  )
}

export default function SegmentListItemWrapper(props) {
  return (
    <JobProvider>
      <DynamicSegmentListItem {...props} />
    </JobProvider>
  )
}
