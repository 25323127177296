import { useMatch, useNavigate } from 'react-router-dom'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export default function MenuListItem({ to, Icon, children }) {
  const navigate = useNavigate()
  const match = Boolean(useMatch(to))

  const handleClick = event => {
    navigate(to)
  }

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Icon sx={{ color: match ? '#0061B4' : 'text.primary' }} />
        </ListItemIcon>
        <ListItemText
          primary={children}
          sx={{
            '& .MuiTypography-root': {
              color: match ? '#0061B4' : 'text.primary',
              fontWeight: match ? 'bold' : 'normal',
            },
            flex: 1,
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}
