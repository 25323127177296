import { createContext, useContext } from 'react'

import { useDocs, where } from 'lib/firestore'

const EventTriggerContext = createContext()

export function EventTriggerProvider({ children }) {
  const { items } = useDocs('event-triggers', where('deleted', '==', false))

  return <EventTriggerContext.Provider value={{ items }} children={children} />
}

export default function useEventTrigger() {
  return useContext(EventTriggerContext)
}
