import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Checkbox } from 'components/Form'
import useSharedData from 'hooks/useSharedData'

export default function DefaultQueryField() {
  const { defaultQueries } = useSharedData()

  return (
    <Accordion sx={{ mb: 2, '&:before': { display: 'none' } }} disableGutters elevation={0}>
      <AccordionSummary
        sx={{
          px: 1,
          color: '#007adf',
          justifyContent: 'left',
          '& .MuiAccordionSummary-content': { flexGrow: 0 },
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: '#007adf' }} />}
      >
        <Typography>詳細条件</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {defaultQueries?.map(x => (
          <Box key={x.id}>
            <Checkbox name={`defaultQueries.${x.id}`} label={x.name} defaultValue={true} />
          </Box>
        ))}
        <Typography variant="caption" color="text.secondary" component="p" sx={{ mt: 1 }}>
          ※動的セグメントを参照している場合、参照先の詳細条件を上書きします。
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}
