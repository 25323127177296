import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

import { Form, SubmitButton, TextField } from 'components/Form'

export default function SendFromMailDialog({ item, emails, onChange, onClose }) {
  const [open, setOpen] = useState(false)
  const form = useForm({ defaultValues: item })

  useEffect(() => {
    setOpen(true)
  }, [])

  const onSubmit = values => {
    onChange(values)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      fullWidth
      maxWidth="xl"
    >
      <Form form={form}>
        <DialogTitle>送信元アドレス追加</DialogTitle>
        <Stack spacing={2} sx={{ px: 3, py: 2 }}>
          <TextField
            name="email"
            label="メールアドレス"
            disabled={Boolean(item.email)}
            rules={{
              required: true,
              validate: v => {
                if (!item.email && emails.includes(v)) {
                  return '既に存在するアドレスです。'
                } else {
                  return true
                }
              },
            }}
            fullWidth
            errorText={form.formState.errors?.name?.message || '必須項目です'}
          />
          <TextField name="name" label="名前" fullWidth />
          <TextField name="permissionSubCategory" label="許諾のサブカテゴリ" fullWidth />
          <TextField
            name="testers"
            label="テストメール送信先の SERIAL ID（カンマ区切り）"
            placeholder="1111111111,2222222222"
            rules={{ required: true }}
            fullWidth
          />
        </Stack>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" onClick={form.handleSubmit(onSubmit)}>
            追加する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
