import { useFormContext } from 'react-hook-form'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { NO_VALUE_OPERATORS, OPERATOR, getOperators } from 'entities/segment'

import { Autocomplete, Select, TextField } from 'components/Form'

export const defaultFilter = { column: null, operator: '=', value: '' }

const inputProps = (type, operator) => {
  if ([OPERATOR.IN, OPERATOR.NOT_IN].includes(operator)) {
    return {
      type: 'text',
      placeholder: 'value1,value2,value3',
    }
  }

  switch (type) {
    case 'DATE':
      return { type: 'date', InputLabelProps: { shrink: true } }
    case 'DATETIME':
    case 'TIMESTAMP':
      return { type: 'datetime-local', InputLabelProps: { shrink: true } }
    case 'INTEGER':
    case 'FLOAT':
    case 'NUMERIC':
      return { type: 'number' }
    case 'BOOLEAN':
      return {
        options: ['true', 'false'],
        convert: v => JSON.parse(v),
      }
    default:
      return { type: 'text' }
  }
}

export default function FilterField({ name, table }) {
  const { watch, setValue } = useFormContext()
  const filter = watch(name)

  const handleChangeOperator = e => {
    const operator = e.target.value
    if ([OPERATOR.IN, OPERATOR.NOT_IN].includes(operator) && !Array.isArray(filter.value)) {
      setValue(`${name}.value`, Boolean(filter.value) ? [filter.value] : [])
    } else if (![OPERATOR.IN, OPERATOR.NOT_IN].includes(operator) && Array.isArray(filter.value)) {
      setValue(`${name}.value`, filter.value.length === 1 ? filter.value[0] : null)
    }
    if (NO_VALUE_OPERATORS.includes(operator)) {
      setValue(`${name}.value`, '')
    }
  }

  const handleChangeColumn = value => {
    if (table?.config.fieldOptions[value?.name]) {
      setValue(
        `${name}.value`,
        [OPERATOR.IN, OPERATOR.NOT_IN].includes(filter.operator) ? [] : null
      )
    } else if (value?.type === 'BOOLEAN') {
      setValue(`${name}.value`, true)
    } else {
      setValue(`${name}.value`, '')
    }
  }

  return (
    <Box sx={{ flex: 1, display: 'flex', gap: 1, alignItems: 'center' }}>
      <Autocomplete
        name={`${name}.column`}
        label="フィールド名（カラム）"
        rules={{
          required: true,
          validate: v =>
            table?.schema.fields.some(x => x.name === v.name)
              ? null
              : '存在しないフィールド名（カラム）です',
        }}
        sx={{ flex: 8 }}
        options={table?.schema.fields || []}
        optionKeys={['name', 'description']}
        renderOptionKeys={['description', 'name']}
        getOptionLabel={option => {
          return Boolean(option.description)
            ? `${option.description} (${option.name})`
            : option.name
        }}
        onChange={handleChangeColumn}
      />
      <Typography sx={{ mx: -0.5 }}>が</Typography>
      {Boolean(table?.config.fieldOptions[filter.column?.name]) ? (
        <Autocomplete
          key={
            [OPERATOR.IN, OPERATOR.NOT_IN].includes(filter.operator)
              ? 'multiple'
              : 'single' /* これを設定しないと単数、複数切り替えの挙動がおかしくなる https://stackoverflow.com/questions/59790956 */
          }
          type="number"
          label="値"
          name={`${name}.value`}
          sx={{
            display: !NO_VALUE_OPERATORS.includes(filter.operator) ? 'inline-flex' : 'none',
            flex: 4,
          }}
          multiple={[OPERATOR.IN, OPERATOR.NOT_IN].includes(filter.operator)}
          freeSolo
          options={table.config.fieldOptions[filter.column.name] || []}
          {...inputProps(filter.column?.type, filter.operator)}
        />
      ) : (
        <TextField
          name={`${name}.value`}
          sx={{
            display: !NO_VALUE_OPERATORS.includes(filter.operator) ? 'inline-flex' : 'none',
            flex: 4,
          }}
          label="値"
          {...inputProps(filter.column?.type, filter.operator)}
        />
      )}
      <Select
        name={`${name}.operator`}
        label="演算子"
        rules={{ required: true }}
        sx={{ flex: 3 }}
        onChange={handleChangeOperator}
      >
        {getOperators(filter.column?.type).map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
