import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import HelpIcon from '@mui/icons-material/Help'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { CONDITION_TYPE } from 'entities/segment'

import { Autocomplete, TextField } from 'components/Form'
import useSharedData from 'hooks/useSharedData'
import { getDoc } from 'lib/firestore'

import AggregateField from './AggregateField'
import DurationField, { defaultDuration } from './DurationField'
import { defaultFilter } from './FilterField'
import TableFieldArray from './TableFieldArray'

export const defaultTable = {
  type: CONDITION_TYPE.TABLE,
  table: null,
  filters: [defaultFilter],
  operator: 'AND',
}

export default function TableFieldGroup({ name }) {
  const sharedData = useSharedData()
  const [table, setTable] = useState(null)
  const { watch, setValue } = useFormContext()
  const [values, idType] = watch([name, 'idType'])
  const tables = sharedData?.tables.filter(x => x.idFieldNames.includes(idType)) || []

  useEffect(() => {
    if (values.table) {
      const [dataset, table] = values.table.name.split('.')
      getDoc(`datasets/${dataset}/tables/${table}`).then(data => {
        setTable(data)

        // 未入力の場合 preset を設定
        if (values.filters.length === 1 && values.filters[0].column === null) {
          if (data.config.presetConditions?.length > 0) {
            setValue(`${name}.filters`, data.config.presetConditions)
          }
          if (data.config.type === 'log') {
            setValue(`${name}.duration`, defaultDuration)
          } else {
            setValue(`${name}.duration`, {})
          }
        }
      })
    }
  }, [values.table, values.filters, setValue, name])

  const handleChangeTable = value => {
    if (values.table?.name !== value?.name) {
      setValue(`${name}.filters`, [defaultFilter])
      setValue(`${name}.lookAlike`, { version: null, limit: '' })
    }
  }

  return (
    <Box
      sx={{
        flex: 1,
        p: 2,
        border: theme => `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 2,
        background: theme => theme.palette.grey[200],
      }}
    >
      <Autocomplete
        name={`${name}.table`}
        label="テーブル名"
        rules={{ required: true }}
        sx={{ mb: 2 }}
        fullWidth
        options={tables}
        optionKeys={['name', 'description']}
        renderOptionKeys={['description', 'name']}
        getOptionLabel={option => {
          return Boolean(option.description)
            ? `${option.description} (${option.name})`
            : option.name
        }}
        onChange={handleChangeTable}
      />
      {table?.config.type === 'log' && <DurationField name={`${name}.duration`} />}
      {table?.config.type === 'snapshot' && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ fontWeight: 'bold', width: 88 }}>日付</Typography>
          <TextField
            name={`${name}.date`}
            type="date"
            sx={{ flex: 1 }}
            label="日付"
            InputLabelProps={{ shrink: true }}
            defaultValue={dayjs().add(-1, 'day').format('YYYY-MM-DD')}
            rules={{ required: true }}
          />
        </Box>
      )}
      <TableFieldArray name={name} table={table} />
      {table?.config.type === 'log' && <AggregateField name={name} table={table} />}
      {table?.config.lookAlike.length > 0 && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 2 }}>
          <Box sx={{ width: 80, display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography sx={{ fontWeight: 'bold' }}>拡張</Typography>
            <Tooltip title="類似ユーザーを含めて検索して対象者を拡張します">
              <IconButton size="small">
                <HelpIcon sx={{ fontSize: 18, color: 'text.secondary' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Autocomplete
            name={`${name}.lookAlike.version`}
            label="バージョン"
            sx={{ flex: 2 }}
            options={table.config.lookAlike}
            optionKeys={['name', 'description']}
            onChange={v => setValue(`${name}.lookAlike.limit`, v !== null ? 200000 : '')}
          />
          <TextField
            name={`${name}.lookAlike.limit`}
            label="上限人数"
            type="number"
            sx={{ flex: 1 }}
          />
        </Box>
      )}
    </Box>
  )
}
