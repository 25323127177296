import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Form, SubmitButton, Switch, TextField } from 'components/Form'
import { updateDoc, useDoc } from 'lib/firestore'

export default function Maintenance() {
  const data = useDoc('shared/maintenance')
  const form = useForm({ defaultValues: {} })

  useEffect(() => {
    if (data.item) {
      form.reset(data.item)
    }
  }, [data.item, form])

  const handleSubmit = values => {
    return updateDoc('shared/maintenance', values)
  }

  return (
    <Box>
      <Form form={form} onSubmit={handleSubmit}>
        <Paper>
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="h6">メンテンナンスモード</Typography>
            <Switch name="isMaintenance" label="メンテナンス中にする" />
            <TextField label="メンテンス文言" name="message" fullWidth multiline />
            <Box sx={{ textAlign: 'right' }}>
              <SubmitButton variant="text">更新</SubmitButton>
            </Box>
          </Stack>
        </Paper>
      </Form>
    </Box>
  )
}
