import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

const firebaseConfig = {
  production: {
    apiKey: 'AIzaSyAN9uRaDXTAA1ksNinkt2laat3rJQLJWXg',
    authDomain: 'pf-ngma.firebaseapp.com',
    projectId: 'pf-ngma',
    storageBucket: 'pf-ngma.appspot.com',
    messagingSenderId: '157825986196',
    appId: '1:157825986196:web:89ff3d1d8dfbc3ed548e81',
    measurementId: 'G-XLKXSM30JJ',
  },
  staging: {
    apiKey: 'AIzaSyCBe6XY1nXlaGBfflA_d8l2_bS3BmQjU1M',
    authDomain: 'pf-ngma-dev.firebaseapp.com',
    projectId: 'pf-ngma-dev',
    storageBucket: 'pf-ngma-dev.appspot.com',
    messagingSenderId: '575696275324',
    appId: '1:575696275324:web:1189a0d4002b088eba06f9',
    measurementId: 'G-38YHDMVNP5',
  },
}[process.env.REACT_APP_ENV || 'staging']

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const db = getFirestore(app)
const functions = getFunctions(app, 'asia-northeast1')
const auth = getAuth(app)
const storage = getStorage(app)

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
  connectStorageEmulator(storage, 'localhost', 9199)
}

export { app, auth, db, functions, storage, analytics }
