import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import SendIcon from '@mui/icons-material/SendTwoTone'
import WarningIcon from '@mui/icons-material/WarningTwoTone'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import DetailPageMenuList from 'components/DetailPageMenuList'
import { Autocomplete, Checkbox, Form, Select, SubmitButton, TextField } from 'components/Form'
import { filter } from 'components/GroupFilter'
import Loading from 'components/Loading'
import { prompt } from 'components/Prompt'
import PublishSettingDialog from 'components/PublishSettingDialog'
import { showSnackbar } from 'components/Snackbar'
import useAuth from 'lib/auth'
import { addDoc, updateDoc, useDoc, useDocs } from 'lib/firestore'
import { httpsCallable } from 'lib/functions'
import { isNaturalNumber } from 'utils'

import ContentFieldArray, { defaultContents } from './ContentFieldArray'
import ErrorDialog from './ErrorDialog'
import SegmentField from './SegmentField'

export const STATUS = Object.freeze({
  PENDING: 'PENDING', // 処理待ち
  READY: 'READY', // 実行待ち
  PROCESSING: 'PROCESSING', // 送信中
  RETRY: 'RETRY', // リトライ
  SUCCESS: 'SUCCESS', // 成功
  ERROR: 'ERROR', // エラー
})

const makeCopy = item => {
  return {
    ...item,
    id: null,
    name: `${item.name} のコピー`,
    job: null,
    result: null,
    approval: { group: false, admin: false },
  }
}

export default function TargetMailDetail() {
  const [open, setOpen] = useState(false)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const params = useParams()
  const { item } = useDoc(`target-mails/${Boolean(params.id) ? params.id : ''}`)
  const { items } = useDocs('target-mails')
  const { user } = useAuth()
  const { items: groups } = useDocs('groups')
  const fromOptions = groups
    .filter(group => user.groups.includes(group.id))
    .flatMap(group =>
      (group.sendFromMails || []).map(x => ({
        name: x.name,
        email: x.email,
        groupId: group.id,
      }))
    )
  const form = useForm({ defaultValues: { contents: defaultContents } })
  const contents = form.watch('contents')
  const navigate = useNavigate()
  const data = item || {}
  const approved = (data.approval?.admin || data.approval?.group) ?? false
  const location = useLocation()

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (item) {
      item.sendAt = dayjs(item.sendAt).format('YYYY-MM-DDTHH:mm')
      form.reset(item)
    }
  }, [item, form])

  useEffect(() => {
    if (location.state?.item) {
      const x = makeCopy(location.state.item)
      x.sendAt = dayjs(x.sendAt).format('YYYY-MM-DDTHH:mm')
      form.reset(x)
    }
  }, [location.state, form])

  useEffect(() => {
    if (contents.length === 1) {
      form.setValue('proportion', 100)
    }
  }, [contents.length, form])

  const handleUpdate = async values => {
    const settings = await prompt(PublishSettingDialog, data)
    if (!settings) return

    values.sendAt = new Date(values.sendAt)

    return updateDoc(`target-mails/${data.id}`, { ...values, ...settings })
      .then(() => setOpen(false))
      .catch(error => form.setError('network', { message: error.message }))
  }

  const handleAdd = async values => {
    const settings = await prompt(PublishSettingDialog)
    if (!settings) return

    values.sendAt = new Date(values.sendAt)
    values.state = STATUS.PENDING

    return addDoc('target-mails', { ...values, ...settings, createdBy: user.id, deleted: false })
      .then(() => setOpen(false))
      .catch(error => {
        console.log(error)
        form.setError('network', { message: error.message })
      })
  }

  const handleResend = async () => {
    setOpenErrorDialog(false)
    const _item = { ...item }
    _item.state = STATUS.PENDING
    _item[`result.errors`] = []
    _item.job = null
    _item.sendAt = new Date(_item.sendAt)
    await updateDoc(`target-mails/${data.id}`, _item).then(() => setOpen(false))
  }

  const handleSendTest = async values => {
    // TODO: バリエーションを選択できるようにする
    return httpsCallable('callable-targetmail-sendtest')({
      item: values,
      id: data.id || 'new',
      contentIndex: 0,
    })
      .then(res => {
        console.log(res)
        showSnackbar(`テスト送信に成功しました。`)
      })
      .catch(e => showSnackbar(`テスト送信に失敗しました。${e.message}`))
  }

  if (params.id && !data.id) {
    return <Loading disableShrink />
  }

  if (params.id && filter(items, '', user).every(x => x.id !== data.id)) {
    return <Navigate to="../" replace />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('/target-mails') }}
      sx={{ '& .MuiPaper-root': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              メール配信
            </Typography>
            <DetailPageMenuList item={item} />
            {item?.state === STATUS.ERROR && (
              <ButtonBase sx={{ px: 2, py: 1, mr: 1 }} onClick={() => setOpenErrorDialog(true)}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <WarningIcon color="error" sx={{ fontSize: 16 }} />
                  <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: 'error.main' }}>
                    送信に失敗しました
                  </Typography>
                </Box>
              </ButtonBase>
            )}
            <SubmitButton
              variant="text"
              sx={{ mr: 2 }}
              startIcon={<SendIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(handleSendTest)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              テスト送信
            </SubmitButton>
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(data.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(data.group) && !user.groups.includes(data.group)}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Stack spacing={3} sx={{ p: 5 }}>
          <TextField
            label="設定名"
            name="name"
            disabled={approved}
            rules={{
              required: true,
              maxLength: 100,
              validate: v => {
                if (items.filter(x => x.id !== data.id).some(x => x.name === v)) {
                  return '既に存在する名前です。'
                } else {
                  return true
                }
              },
            }}
            fullWidth
            placeholder="新しいメール"
            errorText="100 文字以内で入力してください"
          />
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              対象範囲
            </Typography>
            <SegmentField name="target" disabled={approved} />
          </Stack>
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              コンテンツ
            </Typography>
            <TextField
              label="デフォルトバリエーション（バリエーション1）の割合"
              type="number"
              name="proportion"
              disabled={contents.length === 1 || approved}
              defaultValue={100}
              fullWidth
              rules={{ required: true, validate: v => isNaturalNumber(v) && v > 0 && v <= 100 }}
              errorText="1 から 100 の整数で入力して下さい"
            />
            <ContentFieldArray name="contents" control={form.control} disabled={approved} />
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
          <Stack spacing={2}>
            <Typography sx={{ fontWeight: 'bold', color: theme => theme.palette.grey['700'] }}>
              送信設定
            </Typography>
            <Autocomplete
              name="sendFrom"
              disabled={approved}
              label="送信元アドレス"
              options={fromOptions}
              optionKeys={['name', 'email']}
              rules={{ required: true }}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <TextField
                label="日付"
                name="sendAt"
                disabled={approved}
                type="datetime-local"
                sx={{ width: 320 }}
                rules={{ required: true }}
                InputLabelProps={{ shrink: true }}
              />
              <Typography>にメールを送信する</Typography>
            </Stack>
            <Select
              sx={{ width: 320 }}
              name="priority"
              disabled={approved}
              label="配信優先度"
              defaultValue="normal"
            >
              <MenuItem value="normal">普通</MenuItem>
              <MenuItem value="high">優先</MenuItem>
              <MenuItem value="top">送信必須</MenuItem>
            </Select>
            <Stack sx={{ width: 280 }}>
              <Checkbox name="approval.group" label="原局として配信設定を完了" />
              <Checkbox
                name="approval.admin"
                label="管理者として配信を許可"
                disabled={!user.isAdmin}
              />
            </Stack>
          </Stack>
        </Stack>
      </Form>
      {openErrorDialog && (
        <ErrorDialog item={item} onClick={handleResend} onClose={() => setOpenErrorDialog(false)} />
      )}
    </Dialog>
  )
}
