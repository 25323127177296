import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import SendFromMailDialog from './SendFromMailDialog'

export default function SendFromMailList({ name, editable }) {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)
  const { control, watch } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({ control, name })
  const items = watch(name)

  const handleAdd = value => {
    append(value)
  }

  const handleChange = (index, value) => {
    update(index, value)
    setSelectedItem(null)
  }

  const handleRemove = index => {
    remove(index)
    setSelectedItem(null)
  }

  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1 }} />
        <Button
          onClick={() => setOpenDialog(true)}
          color="inherit"
          startIcon={<AddIcon />}
          disabled={!editable}
        >
          送信先アドレス追加
        </Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={{ '&.MuiTableContainer-root': { bgcolor: 'background.paper' } }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell sx={{ width: 280 }}>名前</TableCell>
              <TableCell sx={{ width: 40 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <SendFromMailListItem
                key={index}
                item={item}
                index={index}
                onSelect={setSelectedItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={Boolean(selectedItem)}
        anchorEl={Boolean(selectedItem) && selectedItem.element}
        onClose={() => setSelectedItem(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 80 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpenDialog(true)}>
              <ListItemText primary="編集" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleRemove(selectedItem.index)}>
              <ListItemText primary="削除" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      {openDialog && (
        <SendFromMailDialog
          item={Boolean(selectedItem) ? items[selectedItem.index] : {}}
          emails={items.map(x => x.email)}
          onChange={Boolean(selectedItem) ? v => handleChange(selectedItem.index, v) : handleAdd}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </Stack>
  )
}

const SendFromMailListItem = ({ item, index, onSelect }) => (
  <TableRow sx={{ '&:last-child .MuiTableCell-root': { border: 0 } }}>
    <TableCell>{item.email}</TableCell>
    <TableCell>{item.name}</TableCell>
    <TableCell align="right" sx={{ py: 1 }}>
      <IconButton size="small" onClick={e => onSelect({ index, element: e.currentTarget })}>
        <MoreVertIcon />
      </IconButton>
    </TableCell>
  </TableRow>
)
