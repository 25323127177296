import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { showError } from 'components/ErrorDialog'
import { Form, SubmitButton, TextField } from 'components/Form'
import { ReactComponent as GoogleIcon } from 'images/google.svg'
import { sendSignInLinkToEmail, signInWithGoogle } from 'lib/auth'

export default function Login() {
  const form = useForm()

  const onSubmit = ({ email }) => {
    return sendSignInLinkToEmail(email).catch(() =>
      form.setError('email', { message: '認証リンクの送信に失敗しました。' })
    )
  }

  const handleClickGoogleLogin = () => {
    return signInWithGoogle().catch(() => {
      showError({
        message:
          'Google 認証が正常に完了しませんでした。大変お手数をおかけしますが、再度お試しください。',
      })
    })
  }

  if (form.formState.isSubmitSuccessful) {
    return (
      <Box>
        <Typography variant="h2" sx={{ mb: 5 }}>
          メールBOXをご確認ください
        </Typography>
        <Typography sx={{ mb: 5 }}>
          入力したメールアドレスにログインリンクをお送りします。
          <br />
          メールが届きましたらリンクを開いてログインを完了して下さい。
          <br />
          しばらくしてもメールが届かない場合は、迷惑メールもご確認下さい。
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          同じブラウザでログインリンクを開くと直接ログインできます。
        </Typography>
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <Form form={form} onSubmit={onSubmit}>
          <Typography variant="h2" sx={{ mb: 5 }}>
            ログイン・登録
          </Typography>
          <Box sx={{ mb: 4 }}>
            <Typography sx={{ mb: 0.5 }}>
              ログインリンクを送るためにメールアドレスを入力してください。
            </Typography>
            <Typography>登録済みの方も新しいログインリンクを発行してください。</Typography>
          </Box>
          <TextField
            label="メールアドレス"
            name="email"
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'メールアドレスの形式が不正です',
              },
            }}
            fullWidth
            sx={{ mb: 2 }}
          />
          <SubmitButton>リンクを送信</SubmitButton>
        </Form>
      </Box>
      <Box>
        <Typography sx={{ mb: 2 }}>または</Typography>
        <Button
          onClick={handleClickGoogleLogin}
          size="large"
          color="inherit"
          sx={{
            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 12px rgba(0, 0, 0, 0.08)',
            background: '#fff',
            height: 54,
            width: 240,
            borderRadius: '8px',
            '&:hover': {
              background: '#fafafa',
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 12px rgba(0, 0, 0, 0.08)',
            },
            '& .MuiButton-startIcon': { mr: 3 },
          }}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
      </Box>
    </Box>
  )
}
