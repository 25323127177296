import { Autocomplete } from 'components/Form'
import { filter } from 'components/GroupFilter'
import useAuth from 'lib/auth'
import { useDocs, where } from 'lib/firestore'

export default function SegmentField({ name }) {
  const { user } = useAuth()
  const { items } = useDocs('segments', where('deleted', '==', false))
  const filteredItems = filter(items, '', user)
    .filter(x => {
      // 静的セグメントで結果が出力されていないセグメントは対象外
      if (x.trigger === 'static' && !x.result) return false
      return true
    })
    .map(({ id, name, tags, trigger }) => ({ id, name, tags, trigger }))

  return (
    <Autocomplete
      name={name}
      label="セグメント"
      fullWidth
      InputLabelProps={{ shrink: true }}
      placeholder="指定しない"
      options={filteredItems}
      optionKeys={['id', 'name']}
      renderOptionKeys={['name', 'tags']}
    />
  )
}
