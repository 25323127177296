import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import dayjs from 'dayjs'

import { Form, Select, SubmitButton, TextField } from 'components/Form'

const format = data => {
  const values = structuredClone(data)
  values.sendAt = dayjs(values.sendAt).format('YYYY-MM-DDTHH:mm')
  return values
}

export default function IMSSettingsDialog({ data, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const form = useForm({ defaultValues: format(data) })

  useEffect(() => {
    setOpen(true)
  }, [])

  const handleSubmit = values => {
    onSubmit({ ...values, sendAt: new Date(values.sendAt) })
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Form form={form} onSubmit={handleSubmit}>
        <DialogTitle>IMS 連携情報の設定</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Select
              name="external_list_owner_group_id"
              label="外部リスト所有グループ"
              fullWidth
              defaultValue="IMSCS本部／BPSC"
            >
              <MenuItem value="IMSCS本部／BPSC">IMSCS本部／BPSC</MenuItem>
              <MenuItem value="IMS日経ID 抽出担当者">IMS日経ID 抽出担当者</MenuItem>
              <MenuItem value="IMS日経ID 分析担当者">IMS日経ID 分析担当者</MenuItem>
              <MenuItem value="IMS販売局 抽出担当者">IMS販売局 抽出担当者</MenuItem>
              <MenuItem value="IMS販売局 分析担当者">IMS販売局 分析担当者</MenuItem>
              <MenuItem value="IMS文事 抽出担当者">IMS文事 抽出担当者</MenuItem>
              <MenuItem value="IMSイベレジ 抽出担当者">IMSイベレジ 抽出担当者</MenuItem>
            </Select>
            <TextField
              name="memo"
              label="外部リスト利用 用途"
              rules={{ maxLength: 200 }}
              errorText="200 文字までで入力して下さい"
              fullWidth
              multiline
            />
            <TextField
              name="sendAt"
              type="datetime-local"
              label="連携日時"
              rules={{ required: true }}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            {data.id && (
              <FormHelperText error>
                未実行のジョブが存在します。設定を保存すると既存のジョブが上書きされます。
              </FormHelperText>
            )}
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
            設定する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
