import { useEffect, useReducer } from 'react'

import MuiSnackbar from '@mui/material/Snackbar'

const key = 'snackbar'
const reducer = (x, y) => ({ ...x, ...y })

export default function Snackbar(props) {
  const [state, setState] = useReducer(reducer, props)

  useEffect(() => {
    const handleChange = event => {
      setState({ ...event.detail, open: true })
    }
    window.addEventListener(key, handleChange)
    return () => window.removeEventListener(key, handleChange)
  }, [])

  return <MuiSnackbar {...state} onClose={() => setState({ open: false })} />
}

export const showSnackbar = (message, options = {}) => {
  const event = new CustomEvent(key, { detail: { message, ...options } })
  window.dispatchEvent(event)
}
