import { useState } from 'react'
import { useFieldArray } from 'react-hook-form'

import CodeIcon from '@mui/icons-material/CodeTwoTone'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { v4 as uuidv4 } from 'uuid'

import ContentEditorDialog, { defaultValues } from './ContentEditorDialog'

export const defaultContentValues = defaultValues
const defaultValue = defaultValues['text/html']
export const defaultContents = [
  { name: 'バリエーション 1', id: uuidv4(), value: defaultValue },
  { name: 'バリエーション 2', id: uuidv4(), value: defaultValue },
]

export default function ContentFieldArray({ name, control, language, disabled }) {
  const [targetVariation, setTargetVariation] = useState(null)
  const [editingIndex, setEditingIndex] = useState(-1)
  const { fields, append, insert, remove, update } = useFieldArray({ control, name })

  const handleClickEditor = i => {
    setEditingIndex(i)
  }

  const handleAddVariation = () => {
    append({
      name: `バリエーション ${fields.length + 1}`,
      id: uuidv4(),
      value: defaultValue,
    })
  }

  const handleChangeVariation = (index, values) => {
    update(index, values)
  }

  const handleRemoveVariation = () => {
    if (fields.length > 1) {
      remove(targetVariation.index)
    }
    setTargetVariation(null)
  }

  const handleDuplicateVariation = () => {
    const values = fields[targetVariation.index]
    console.log(values)
    insert(targetVariation.index + 1, { ...values, name: `${values.name} のコピー`, id: uuidv4() })
    setTargetVariation(null)
  }

  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 2,
          border: theme => `1px solid ${theme.palette.grey[500]}`,
          borderRadius: 2,
          background: theme => theme.palette.grey[100],
          '& .MuiBox-root:not(:last-child)': {
            borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
          },
        }}
      >
        {fields.map((_, i) => (
          <Box key={i} sx={{ display: 'flex', alignItems: 'center', py: 1, gap: 2 }}>
            <Typography sx={{ flex: 1 }}>{fields[i]?.name}</Typography>
            <Button variant="text" onClick={() => handleClickEditor(i)} startIcon={<CodeIcon />}>
              エディタを開く
            </Button>
            <IconButton onClick={e => setTargetVariation({ index: i, element: e.currentTarget })}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        ))}
        <Box sx={{ py: 1 }}>
          <Button variant="text" onClick={handleAddVariation}>
            バリエーションを追加
          </Button>
        </Box>
        <Popover
          open={Boolean(targetVariation)}
          anchorEl={Boolean(targetVariation) && targetVariation.element}
          onClose={() => setTargetVariation(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List sx={{ minWidth: 80 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleDuplicateVariation()}>
                <ListItemText primary="複製" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleRemoveVariation()} disabled={fields.length <= 1}>
                <ListItemText primary="削除" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>
      {editingIndex >= 0 && (
        <ContentEditorDialog
          language={language}
          disabled={disabled}
          defaultValue={fields[editingIndex]}
          onChange={values => handleChangeVariation(editingIndex, values)}
          onClose={() => setEditingIndex(-1)}
        />
      )}
    </>
  )
}
