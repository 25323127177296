import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

export default function GroupListItem({ item, onClick, onDelete }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDelete = () => {
    onDelete()
    setAnchorEl(null)
  }

  return (
    <>
      <Box sx={{ mb: 2, pr: 2, bgcolor: '#fff', borderRadius: '4px', display: 'flex' }}>
        <ButtonBase
          onClick={onClick}
          sx={{ display: 'block', flex: 1, p: 2, textAlign: 'left' }}
          centerRipple={true}
        >
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>{item.name}</Typography>
          <Typography variant="caption">{item.members.length} 人</Typography>
        </ButtonBase>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 120 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleDelete()}>
              <ListItemText primary="削除" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
