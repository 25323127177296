import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveIcon from '@mui/icons-material/SaveTwoTone'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { MEMBER_ROLE } from 'entities/group'

import { showError } from 'components/ErrorDialog'
import { Form, SubmitButton, TextField } from 'components/Form'
import Loading from 'components/Loading'
import useAuth from 'lib/auth'
import { addDoc, updateDoc, useDoc, useDocs } from 'lib/firestore'

import MemberDialog from './MemberDialog'
import MemberList from './MemberList'
import SendFromMailList from './SendFromMailList'

export default function GroupDetail() {
  const [open, setOpen] = useState(false)
  const [openMemberDialog, setOpenMemberDialog] = useState(false)
  const params = useParams()
  const { item } = useDoc(`groups/${Boolean(params.id) ? params.id : ''}`)
  const { items } = useDocs('groups')
  const { user } = useAuth()
  const isMember = user.groups.includes(params.id)
  const isOwner = item?.members?.find(x => x.id === user.id)?.role === MEMBER_ROLE.OWNER
  const form = useForm()
  const members = form.watch('members') || []
  const navigate = useNavigate()

  useEffect(() => {
    setOpen(true)
  }, [form])

  useEffect(() => {
    if (item?.id) {
      form.reset(item)
    }
  }, [item, form])

  const updateGroup = values => {
    return updateDoc(`groups/${item.id}`, values).catch(error =>
      showError({ message: `グループの更新に失敗しました。\n${error.message}` })
    )
  }

  const handleUpdate = values => {
    return updateGroup(values).then(() => setOpen(false))
  }

  const handleAdd = values => {
    const owner = { id: user.id, email: user.email, role: MEMBER_ROLE.OWNER }
    return addDoc('groups', {
      ...values,
      createdBy: user.id,
      members: [owner],
      deleted: false,
    })
      .then(x => navigate(`/groups/${x.id}`))
      .catch(error => showError({ message: `グループの作成に失敗しました。\n${error.message}` }))
  }

  const handleChangeMembers = newMembers => {
    return updateGroup({ members: [...item.members, ...newMembers] })
  }

  const handleChangeMember = (id, newRole) => {
    const members = item.members.map(x => {
      return x.id === id ? { ...x, role: newRole } : x
    })
    return updateGroup({ members })
  }

  // TODO: 他の部分も含めて、この時点では保存せずに保存ボタン押下時に保存するようにする
  const handleDeleteMember = id => {
    const members = item.members.filter(x => x.id !== id)
    return updateGroup({ members })
  }

  if (params.id && !item?.id) {
    return <Loading />
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: () => navigate('/groups') }}
      sx={{ '& .MuiPaper-root': { bgcolor: 'background.default' } }}
    >
      <Form form={form}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => setOpen(false)}
              disabled={form.formState.isSubmitting}
              edge="start"
              sx={{ mr: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flex: 1 }}>
              グループ
            </Typography>
            <SubmitButton
              startIcon={<SaveIcon size={12} color="#fff" />}
              onClick={form.handleSubmit(Boolean(item?.id) ? handleUpdate : handleAdd)}
              disabled={Boolean(params.id) && !isMember}
            >
              保存
            </SubmitButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Toolbar />
        <Box sx={{ display: 'flex' }}>
          <Stack spacing={3} sx={{ flex: 1, p: 5 }}>
            <Stack spacing={2}>
              <TextField
                label="グループ名"
                name="name"
                rules={{
                  required: true,
                  maxLength: 100,
                  validate: v => {
                    if (items.filter(x => x.id !== item?.id).some(x => x.name === v)) {
                      return '既に存在する名前です。'
                    } else {
                      return true
                    }
                  },
                }}
                fullWidth
                placeholder="新しいグループ"
                errorText="100 文字以内で入力してください"
              />
            </Stack>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1 }} />
                <Button
                  onClick={() => setOpenMemberDialog(true)}
                  color="inherit"
                  startIcon={<AddIcon />}
                  disabled={item === null || !isOwner}
                >
                  メンバー追加
                </Button>
              </Box>
              <MemberList
                members={members}
                isOwner={isOwner}
                onChange={handleChangeMember}
                onDelete={handleDeleteMember}
              />
              {openMemberDialog && (
                <MemberDialog
                  members={members || null}
                  onSubmit={handleChangeMembers}
                  onClose={() => setOpenMemberDialog(false)}
                />
              )}
            </Stack>
            <SendFromMailList name="sendFromMails" editable={isMember} />
            <TextField label="メモ" name="memo" fullWidth multiline />
          </Stack>
        </Box>
      </Form>
    </Dialog>
  )
}
