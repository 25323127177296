import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'

import { confirm } from 'components/Confirm'
import GroupFilter, { filter as filterWithGroup } from 'components/GroupFilter'
import { showSnackbar } from 'components/Snackbar'
import Sort from 'components/Sort'
import TagFilter, { filter as filterWithTags } from 'components/TagFilter'
import useLocalStorage from 'hooks/useLocalStorage'
import { SegmentProvider } from 'hooks/useSegment'
import useAuth from 'lib/auth'
import { orderBy, updateDoc, useDocs, where } from 'lib/firestore'

import DynamicSegmentListItem from './DynamicSegmentListItem'
import StaticSegmentListItem from './StaticSegmentListItem'

export default function SegmentList() {
  const [filterCondition, setFilterCondition] = useLocalStorage('filterConditionSegmentList', {
    group: '',
    tags: [],
  })
  const navigate = useNavigate()
  const [tags, setTags] = useState(filterCondition.tags)
  const [group, setGroup] = useState(filterCondition.group)
  const [order, setOrder] = useState(['createdAt', 'desc'])
  const { items: groups } = useDocs('groups')
  const { user } = useAuth()
  const { items } = useDocs('segments', where('deleted', '==', false), orderBy(...order))

  const segments = useMemo(() => {
    let _items = [...items]
    _items = filterWithGroup(_items, group, user)
    _items = filterWithTags(_items, tags)
    return _items
  }, [items, user, tags, group])

  const handleDuplicate = async item => {
    navigate('./new', { state: { item } })
  }

  const handleDelete = async item => {
    if (
      await confirm({
        title: 'セグメントを削除してもよろしいですか？',
        body: '一度削除すると復元することはできません。',
      })
    ) {
      updateDoc(`segments/${item.id}`, { deleted: true }).catch(() =>
        showSnackbar('セグメントの削除に失敗しました')
      )
    }
  }

  const handleChangeGroup = newValue => {
    setGroup(newValue)
    setFilterCondition({ ...filterCondition, group: newValue })
  }

  const handleChangeTags = newValue => {
    setTags(newValue)
    setFilterCondition({ ...filterCondition, tags: newValue })
  }

  return (
    <SegmentProvider>
      <Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <GroupFilter value={group} onChange={handleChangeGroup} />
          <TagFilter value={tags} onChange={handleChangeTags} />
          <Sort value={order} onChange={setOrder} />
        </Box>
        <Box>
          {segments.map(x =>
            x.trigger === 'dynamic' ? (
              <DynamicSegmentListItem
                key={x.id}
                item={x}
                group={groups.find(y => y.id === x.group)}
                editable={!x.group || user.groups?.includes(x.group)}
                onClick={() => navigate(`./${x.id}`)}
                onDelete={() => handleDelete(x)}
                onDuplicate={() => handleDuplicate(x)}
              />
            ) : (
              <StaticSegmentListItem
                key={x.id}
                item={x}
                group={groups.find(y => y.id === x.group)}
                editable={!x.group || user.groups?.includes(x.group)}
                onClick={() => navigate(`./${x.id}`)}
                onDelete={() => handleDelete(x)}
                onDuplicate={() => handleDuplicate(x)}
              />
            )
          )}
        </Box>
        <Fab onClick={() => navigate('./new')}>
          <AddIcon />
        </Fab>
      </Box>
    </SegmentProvider>
  )
}
