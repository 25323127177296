import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function ErrorDialog({ item, onClick, onClose }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <DialogTitle>メール配信</DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Typography>
            メール配信に失敗しました。以下 {item.result.errors.length} 件のエラーが発生しました。
          </Typography>
          {item.result.errors.map(error => (
            <Box sx={{ px: 2, borderRadius: '4px', bgcolor: 'grey.300' }}>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(error, null, 2)}</pre>
            </Box>
          ))}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false)
            onClose()
          }}
        >
          キャンセル
        </Button>
        <Button
          onClick={() => {
            onClick()
            setOpen(false)
          }}
        >
          メールを再送
        </Button>
      </DialogActions>
    </Dialog>
  )
}
