import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { USER_STATUS } from 'entities/user'

import { signOut } from 'lib/auth'

export default function Pending({ user }) {
  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {user.status === USER_STATUS.DELETED ? 'ご利用が制限されています' : '利用申請中です'}
      </Typography>
      <Typography sx={{ mb: 5 }}>
        {user.status === USER_STATUS.DELETED ? (
          <>
            サービスを利用する必要がある場合には、
            <br />
            大変お手数をおかけしますが管理者にお問い合わせください
          </>
        ) : (
          '管理者の承認後にサービスをご利用いただけます。'
        )}
      </Typography>
      <Button variant="text" onClick={signOut}>
        別のメールアドレスでログインする
      </Button>
    </Box>
  )
}
