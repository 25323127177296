import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import ChartJs from 'chart.js/auto'

export default function Chart({ data }) {
  const ref = useRef(null)
  const [_data, setData] = useState(null)

  useEffect(() => {
    if (JSON.stringify(data?.data.datasets) !== JSON.stringify(_data?.data.datasets)) {
      setData(data)
    }
  }, [data, _data])

  useLayoutEffect(() => {
    if (!_data) return
    const chart = new ChartJs(ref.current.getContext('2d'), _data)
    return () => chart.destroy()
  }, [_data])

  return <canvas ref={ref} />
}
