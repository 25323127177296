import Box from '@mui/material/Box'

import { TextField } from 'components/Form'

const { Parser } = require('node-sql-parser')
export const defaultManualInputValue = { text: '' }

// TODO: SQL インジェクションの回避
export default function ManualInputField({ name }) {
  return (
    <Box sx={{ flex: 1 }}>
      <TextField
        name={`${name}.text`}
        label="条件を手入力"
        placeholder={`JSON_VALUE(field, "$.key") = value`}
        fullWidth
        multiline
        maxRows={5}
        rules={{
          validate: v => {
            try {
              const parser = new Parser()
              const options = { database: 'bigquery' }
              const sql = `SELECT * FROM t WHERE ${v}`
              const ast = parser.astify(sql, options)
              parser.sqlify(ast, options)
              // 異常終了しなければ SQL 構文として正しいと判断し true を返す
              return true
            } catch (error) {
              return false
            }
          },
        }}
        errorText="入力形式に誤りがあります"
      />
    </Box>
  )
}
