import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { conditionFieldOptions } from 'entities/eventTrigger'

import { Autocomplete, Select, TextField } from 'components/Form'

export const defaultCondition = {
  field: '',
  type: 'string',
  value: '',
  operator: 'match',
}

const types = [
  { label: '文字列', value: 'string' },
  { label: '数字', value: 'number' },
  { label: '真偽値', value: 'boolean' },
]

export default function ConditionField({ name, onDelete, ...props }) {
  const [type, setType] = useState('string')
  const { setValue, watch } = useFormContext()
  const condition = watch(name)

  const operators = useMemo(() => {
    return [
      { label: '完全一致', value: 'match', disabled: false },
      { label: '前方一致', value: 'prefix', disabled: false },
      { label: '正規表現にマッチ', value: 'regexp', disabled: false },
      { label: 'ワイルドカードパターンにマッチ', value: 'wildcard', disabled: false },
      { label: '存在する', value: 'exists', disabled: false },
      { label: '未満', value: 'range.lt', disabled: condition.type !== 'number' },
      { label: '以下', value: 'range.lte', disabled: condition.type !== 'number' },
      { label: '以上', value: 'range.gte', disabled: condition.type !== 'number' },
      { label: 'より大きい', value: 'range.gt', disabled: condition.type !== 'number' },
    ]
  }, [condition])

  const handleChangeOperator = e => {
    setValue(`${name}.value`, '')
    setType(e.target.value)
  }

  return (
    <Stack {...props} direction="row" spacing={2}>
      <Autocomplete
        label="フィールド"
        name={`${name}.field`}
        fullWidth
        sx={{ flex: 2 }}
        freeSolo
        rules={{ required: true }}
        optionKeys={['name', 'description']}
        options={conditionFieldOptions}
      />
      {condition.operator !== 'exists' && (
        <>
          <Select
            name={`${name}.type`}
            label="データ型"
            fullWidth
            rules={{ required: true }}
            sx={{ flex: 1 }}
            defaultValue="string"
            onChange={handleChangeOperator}
          >
            {types.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <ValueField
            name={`${name}.value`}
            type={type}
            label="値"
            fullWidth
            sx={{ flex: 1 }}
            rules={{ required: true }}
          />
        </>
      )}
      <Select
        name={`${name}.operator`}
        label="演算子"
        fullWidth
        rules={{ required: true }}
        sx={{ flex: 1 }}
        defaultValue="match"
      >
        {operators.map(({ label, value, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}

const ValueField = props => {
  if (props.type === 'boolean') {
    props = { ...props, options: ['true', 'false'], convert: v => JSON.parse(v) }
  }

  return <TextField {...props} />
}
