import { useEffect, useState } from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import { useLocation, useParams } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import Loading from 'components/Loading'
import { showSnackbar } from 'components/Snackbar'
import { orderBy, updateDoc, useDocs } from 'lib/firestore'

export default function HistoryDialog({ ignoreKeys = ['id', 'updatedAt'], onClose }) {
  const [open, setOpen] = useState(false)
  const params = useParams()
  const location = useLocation()
  const collectionName = location.pathname.split('/')[1]
  const { items: users } = useDocs('users')
  const { items } = useDocs(
    `${collectionName}/${params.id}/histories`,
    orderBy('updatedAt', 'desc')
  )
  const [newValue, setNewValue] = useState(null)
  const [oldValue, setOldValue] = useState(null)

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    if (items.length >= 2) {
      setNewValue(items[0])
      setOldValue(items[1])
    }
  }, [items])

  if (!newValue || !oldValue) {
    return <Loading />
  }

  const format = value => {
    const replacer = (key, value) =>
      value instanceof Object && !(value instanceof Array)
        ? Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
              sorted[key] = value[key]
              return sorted
            }, {})
        : value
    const v = { ...value }
    ignoreKeys.forEach(key => {
      delete v[key]
    })
    return JSON.stringify(v, replacer, 2)
  }

  const findUserEmail = id => {
    const user = users.find(x => x.id === id)
    if (user) {
      return user.email
    } else {
      return id
    }
  }

  const handleChange = e => {
    setOldValue(items.find(x => x.id === e.target.value))
  }

  const handleRestore = () => {
    const v = { ...oldValue }
    ignoreKeys.forEach(key => {
      delete v[key]
    })
    return updateDoc(`${collectionName}/${params.id}`, v)
      .then(() => setOpen(false))
      .catch(() => showSnackbar('データの復元に失敗しました'))
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      sx={{ '& .MuiDialog-paper': { bgcolor: 'background.default' } }}
    >
      <AppBar position="fixed" color="primary" elevation={0}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => setOpen(false)} edge="start" sx={{ mr: 2 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flex: 1 }}>
            編集履歴
          </Typography>
          <Button
            startIcon={<RefreshIcon size={12} color="primary" />}
            color="inherit"
            sx={{ color: 'text.primary' }}
            onClick={handleRestore}
          >
            復元する
          </Button>
        </Toolbar>
        <Divider />
      </AppBar>
      <Toolbar />
      <Box sx={{ p: 5 }}>
        <Box sx={{ display: 'flex', mb: 1, '& .MuiBox-root': { flex: 1 } }}>
          <Box>
            <Typography sx={{ mb: 1, fontSize: 16, fontWeight: 'bold', color: 'text.secondary' }}>
              比較元
            </Typography>
            <Select sx={{ width: 400 }} value={oldValue.id} onChange={handleChange}>
              {items.map((x, i) => (
                <MenuItem key={x.id} value={x.id} disabled={i === 0}>
                  {dayjs(x.updatedAt).format('YYYY-MM-DD HH:mm')}　{findUserEmail(x.createdBy)}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Typography sx={{ mb: 1, fontSize: 16, fontWeight: 'bold', color: 'text.secondary' }}>
              比較対象
            </Typography>
            <Select sx={{ width: 400 }} value={newValue.id} disabled>
              {[newValue].map(x => (
                <MenuItem key={x.id} value={x.id}>
                  {dayjs(x.updatedAt).format('YYYY-MM-DD HH:mm')}　{findUserEmail(x.createdBy)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <ReactDiffViewer
          oldValue={format(oldValue)}
          newValue={format(newValue)}
          splitView={true}
          disableWordDiff={true}
          styles={{ line: { wordBreak: 'break-word' } }}
        />
      </Box>
    </Dialog>
  )
}
