import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { USER_STATUS } from 'entities/user'
import {
  GoogleAuthProvider,
  isSignInWithEmailLink as _isSignInWithEmailLink,
  signInWithEmailLink as _signInWithEmailLink,
  signOut as _signOut,
  onAuthStateChanged,
  signInWithPopup,
} from 'firebase/auth'

import { auth } from 'lib/firebase'
import { updateDoc, useDoc } from 'lib/firestore'
import { httpsCallable } from 'lib/functions'

const KEY_SIGNIN_INFO = 'SIGNIN_INFO'
const storage = window.localStorage
const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [data, setData] = useState({ user: null, claims: null, initialized: false })
  const { item: user } = useDoc(Boolean(data.user) ? `users/${data.user.uid}` : null)
  if (user && data.claims && user.status === data.claims.status) {
    user.isAdmin = user.status === USER_STATUS.ADMIN
    user.isUser = [USER_STATUS.ADMIN, USER_STATUS.USER].includes(user.status)
  }

  useEffect(() => {
    return onAuthStateChanged(auth, user => {
      setData({ user, initialized: true })
      if (user) {
        updateDoc(`users/${user?.uid}`, { lastLoginAt: new Date() })
      }
    })
  }, [])

  useEffect(() => {
    if (user && data.user && (!data.claims || data.claims.status !== user.status)) {
      data.user.getIdTokenResult(true).then(res => setData({ ...data, claims: res.claims }))
    }
  }, [user, data])

  const loading = useMemo(() => {
    if (!data.initialized) return true
    if (data.user && !user) return true
    if (user && user.isUser === undefined) return true
    return false
  }, [data, user])

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
      }}
      children={children}
    />
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider()
  provider.addScope('email')
  return signInWithPopup(auth, provider)
}

export const signInWithEmailLink = email => {
  return _signInWithEmailLink(auth, email, window.location.href)
    .then(() => storage.removeItem(KEY_SIGNIN_INFO))
    .catch(error => {
      if (error.code === 'auth/invalid-email') {
        error.message =
          'ログインに失敗しました。認証リンクを受信したメールアドレスを入力してください'
      } else {
        error.message = 'ログインに失敗しました。'
      }
      throw error
    })
}

export const sendSignInLinkToEmail = email => {
  const actionCodeSettings = { url: `${window.location.origin}/finishSignUp` }
  const params = window.location.search
    .slice(1)
    .split('&')
    .map(x => {
      const [k, v] = x.split('=')
      return { [k]: v }
    })
    .filter(x => 'continue' in x)
  return httpsCallable('callable-auth-sendsigninlinktoemail')({ email, actionCodeSettings }).then(
    () =>
      storage.setItem(KEY_SIGNIN_INFO, JSON.stringify({ email, url: params[0]?.continue || '/' }))
  )
}

export const signOut = () => {
  return _signOut(auth)
}

export const isSignInWithEmailLink = url => {
  return _isSignInWithEmailLink(auth, url)
}

export const getSignInInfo = () => {
  try {
    const info = JSON.parse(storage.getItem(KEY_SIGNIN_INFO))
    info.url = decodeURIComponent(info.url)
    return info
  } catch (error) {
    return { email: null, url: null }
  }
}
