import { useEffect, useState } from 'react'

const key = 'prompt'

export default function Prompt() {
  const [state, setState] = useState(null)
  const { Component, data, resolve } = state || {}

  useEffect(() => {
    const handleChange = e => setState(e.detail)
    window.addEventListener(key, handleChange)
    return () => window.removeEventListener(key, handleChange)
  }, [])

  const handleSubmit = value => {
    resolve(value)
    setState(null)
  }

  const handleCancel = () => {
    resolve(null)
    setState(null)
  }

  if (!Boolean(Component)) {
    return null
  }

  return <Component data={data} onSubmit={handleSubmit} onClose={handleCancel} />
}

export const prompt = (Component, data = {}) => {
  return new Promise(resolve => {
    const event = new CustomEvent(key, { detail: { Component, data, resolve } })
    window.dispatchEvent(event)
  })
}
