import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormHelperText from '@mui/material/FormHelperText'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'

import { Autocomplete, Form, Select, SubmitButton, TextField } from 'components/Form'
import Loading from 'components/Loading'
import useSharedData from 'hooks/useSharedData'
import useAuth from 'lib/auth'
import { useDocs, where } from 'lib/firestore'

export default function PublishSettingDialog({ data, onSubmit, onClose }) {
  const [open, setOpen] = useState(false)
  const form = useForm({ defaultValues: { tags: [], ...data } })
  const sharedData = useSharedData()
  const { user } = useAuth()
  const { items } = useDocs('groups', where('deleted', '==', false))
  const groups = user.isAdmin ? items : items.filter(x => x.members.some(y => y.id === user.id))
  const group = form.watch('group')

  useEffect(() => {
    setOpen(true)
  }, [data])

  const handleChangeGroup = e => {
    if (e.target.value === '') {
      form.setValue('private', true)
    }
  }

  const handleSubmit = values => {
    const { group, private: _private, tags, memo } = values
    onSubmit({ group, private: _private, tags, memo })
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <Form form={form} onSubmit={handleSubmit}>
        <DialogTitle>公開設定</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            {Boolean(data.group) && items.length === 0 ? (
              <Loading />
            ) : (
              <>
                <Select
                  name="group"
                  label="グループ"
                  fullWidth
                  defaultValue=""
                  onChange={handleChangeGroup}
                >
                  <MenuItem value="">個人</MenuItem>
                  {groups.map(group => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  name="private"
                  label="公開範囲"
                  fullWidth
                  defaultValue={true}
                  disabled={group === ''}
                >
                  <MenuItem value={false}>全体公開</MenuItem>
                  <MenuItem value={true}>非公開</MenuItem>
                </Select>
                <Autocomplete
                  label="タグ"
                  name="tags"
                  fullWidth
                  freeSolo
                  multiple
                  placeholder="新しいタグ"
                  options={sharedData?.tags || []}
                />
                <TextField
                  label="メモ"
                  rules={{ maxLength: 200 }}
                  errorText="200 文字までで入力して下さい"
                  name="memo"
                  fullWidth
                  multiline
                />
              </>
            )}
            {form.formState.errors.network && (
              <FormHelperText error>{form.formState.errors.network.message}</FormHelperText>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" sx={{ fontWeight: 'bold' }}>
            設定する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}
