import { Navigate, Route, Routes } from 'react-router-dom'

import ActivationDetail from 'components/Activation/Detail'
import ActivationList from 'components/Activation/List'
import FinishSignUp from 'components/Auth/FinishSignUp'
import AuthLayout from 'components/Auth/Layout'
import Login from 'components/Auth/Login'
import Pending from 'components/Auth/Pending'
import EventTriggerDetail from 'components/EventTrigger/Detail'
import EventTriggerList from 'components/EventTrigger/List'
import GroupDetail from 'components/Group/Detail'
import GroupList from 'components/Group/List'
import IPList from 'components/IP/List'
import Layout from 'components/Layout'
import Loading from 'components/Loading'
import Maintenance from 'components/Maintenance'
import NotificationList from 'components/Notification/List'
import SegmentDetail from 'components/Segment/Detail'
import SegmentList from 'components/Segment/List'
import Settings from 'components/Settings'
import TargetMailDetail from 'components/TargetMail/Detail'
import TargetMailList from 'components/TargetMail/List'
import UserList from 'components/User/List'
import UserLabelDetail from 'components/UserLabel/Detail'
import UserLabelList from 'components/UserLabel/List'
import { SharedDataProvider } from 'hooks/useSharedData'
import useAuth from 'lib/auth'
import { useDoc } from 'lib/firestore'

export default function App() {
  const { user, loading } = useAuth()
  const maintenance = useDoc('shared/maintenance')

  if (loading || maintenance.loading) {
    return <Loading />
  }

  // メンテナンス中
  if (maintenance.item?.isMaintenance && !user?.isAdmin) {
    return (
      <Routes>
        <Route path="*" element={<AuthLayout />}>
          <Route index element={<Maintenance />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    )
  }

  // 未ログイン
  if (!user) {
    const params = !['/login', '/segments', '/'].includes(window.location.pathname)
      ? `?continue=${encodeURIComponent(window.location.pathname)}`
      : ''
    return (
      <Routes>
        <Route path="*" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="finishSignUp" element={<FinishSignUp />} />
          <Route path="*" element={<Navigate to={`login${params}`} replace />} />
        </Route>
      </Routes>
    )
  }

  // 承認待ち OR 削除済み
  if (!user.isUser) {
    return (
      <Routes>
        <Route path="*" element={<AuthLayout />}>
          <Route path="pending" element={<Pending user={user} />} />
          <Route path="*" element={<Navigate to="pending" replace />} />
        </Route>
      </Routes>
    )
  }

  return (
    <SharedDataProvider>
      <Routes>
        <Route path="*" element={<Layout />}>
          <Route path="segments">
            <Route index element={<SegmentList />} />
            <Route path=":id" element={<SegmentDetail />} />
            <Route path="new" element={<SegmentDetail />} />
          </Route>
          <Route path="user-labels">
            <Route index element={<UserLabelList />} />
            <Route path=":id" element={<UserLabelDetail />} />
            <Route path="new" element={<UserLabelDetail />} />
          </Route>
          <Route path="event-triggers">
            <Route index element={<EventTriggerList />} />
            <Route path=":id" element={<EventTriggerDetail />} />
            <Route path="new" element={<EventTriggerDetail />} />
          </Route>
          <Route path="target-mails">
            <Route index element={<TargetMailList />} />
            <Route path=":id" element={<TargetMailDetail />} />
            <Route path="new" element={<TargetMailDetail />} />
          </Route>
          <Route path="activations">
            <Route index element={<ActivationList />} />
            <Route path=":id" element={<ActivationDetail />} />
            <Route path="new" element={<ActivationDetail />} />
          </Route>
          <Route path="groups">
            <Route index element={<GroupList />} />
            <Route path=":id" element={<GroupDetail />} />
            <Route path="new" element={<GroupDetail />} />
          </Route>
          <Route path="users" element={<UserList />} />
          <Route path="notifications" element={<NotificationList />} />
          <Route path="ips" element={<IPList />} />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="segments" replace />} />
        </Route>
      </Routes>
    </SharedDataProvider>
  )
}
