import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { MEMBER_ROLE } from 'entities/group'

export default function MemberList({ members, isOwner, onChange, onDelete }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ '&.MuiTableContainer-root': { bgcolor: 'background.paper' } }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell sx={{ width: 160 }}>権限</TableCell>
            <TableCell sx={{ width: 40 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map(x => (
            <MemberListItem
              key={x.id}
              member={x}
              isOwner={isOwner}
              numOfOwners={members.filter(x => x.role === MEMBER_ROLE.OWNER).length}
              onChange={onChange}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MemberListItem = ({ member, isOwner, numOfOwners, onChange, onDelete }) => {
  return (
    <TableRow sx={{ '&:last-child .MuiTableCell-root': { border: 0 } }}>
      <TableCell>{member.email}</TableCell>
      <TableCell padding="none">
        <Select
          value={member.role}
          sx={{
            minWidth: 160,
            '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
          }}
          disabled={!isOwner || (numOfOwners === 1 && member.role === MEMBER_ROLE.OWNER)}
          onChange={e => onChange(member.id, e.target.value)}
        >
          <MenuItem value={MEMBER_ROLE.OWNER}>グループ管理者</MenuItem>
          <MenuItem value={MEMBER_ROLE.EDITOR}>メンバー</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right" sx={{ py: 1 }}>
        {member.role !== MEMBER_ROLE.OWNER && isOwner && (
          <IconButton size="small" onClick={() => onDelete(member.id)}>
            <RemoveCircleOutlineIcon color="error" />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
