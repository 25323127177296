import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import { showSnackbar } from 'components/Snackbar'
import { deleteDoc, orderBy, useDocs } from 'lib/firestore'

import IPDialog from './Dialog'

export default function IPList() {
  const [data, setData] = useState(null)
  const { items } = useDocs('ips', orderBy('createdAt', 'desc'))

  const handleDelete = item => {
    deleteDoc(`ips/${item.id}`).catch(() => showSnackbar('お知らせの削除に失敗しました'))
  }

  return (
    <Box>
      {items.map(x => (
        <IPListItem
          key={x.id}
          item={x}
          onClick={() => setData(x)}
          onDelete={() => handleDelete(x)}
        />
      ))}
      <Fab onClick={() => setData({})}>
        <AddIcon />
      </Fab>
      {data && <IPDialog data={data} onClose={() => setData(null)} />}
    </Box>
  )
}

const IPListItem = ({ item, onClick, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleDelete = () => {
    onDelete()
    setAnchorEl(null)
  }

  return (
    <>
      <Box sx={{ mb: 2, bgcolor: '#fff', borderRadius: '4px', display: 'flex' }}>
        <ButtonBase
          onClick={onClick}
          sx={{ display: 'block', flex: 1, p: 2, textAlign: 'left', overflow: 'hidden' }}
          centerRipple={true}
        >
          <Typography noWrap sx={{ fontSize: 16, fontWeight: 'bold', mb: 1 }}>
            {item.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {item.ip}
          </Typography>
        </ButtonBase>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 120 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleDelete()}>
              <ListItemText primary="削除" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
