import { useEffect, useState } from 'react'

import MonacoEditor from '@monaco-editor/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'

export default function PreviewDialog({ content, onClick, onClose }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <DialogTitle>プレビュー</DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ height: 400, border: '1px solid #9e9e9e' }}>
          <MonacoEditor
            options={{
              fontSize: 14,
              formatOnPaste: true,
              readOnly: true,
              scrollBeyondLastLine: false,
            }}
            language="json"
            value={content}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClick(content)
            setOpen(false)
          }}
        >
          クリップボードにコピー
        </Button>
      </DialogActions>
    </Dialog>
  )
}
