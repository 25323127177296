import HeightIcon from '@mui/icons-material/Height'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

export default function Sort({ value, additionalOptions = [], onChange }) {
  return (
    <FormControl>
      <InputLabel>ソート</InputLabel>
      <Select
        value={value}
        sx={{
          mb: 1,
          minWidth: 120,
          '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
        }}
        onChange={e => onChange(e.target.value.split(','))}
        IconComponent={() => <HeightIcon sx={{ fontSize: 16 }} />}
      >
        <MenuItem value="createdAt,desc">作成日 降順</MenuItem>
        <MenuItem value="createdAt,asc">作成日 昇順</MenuItem>
        <MenuItem value="updatedAt,desc">更新日 降順</MenuItem>
        <MenuItem value="updatedAt,asc">更新日 昇順</MenuItem>
        {additionalOptions.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
