import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import useAuth from 'lib/auth'
import { useDocs, where } from 'lib/firestore'

const PERSONAL = 'PERSONAL'

export const filter = (items, group, user) => {
  if (items === null) return []
  if (group === '') {
    if (user.isAdmin) return items
    return items.filter(x => {
      if (user.groups?.includes(x.group)) return true
      if (x.createdBy === user.id) return true
      if (x.private === false) return true
      return false
    })
  } else if (group === PERSONAL) {
    return items.filter(x => x.group === '' && x.createdBy === user.id)
  } else {
    return items.filter(x => x.group === group)
  }
}

export default function GroupFilter({ value, onChange }) {
  const { items } = useDocs('groups', where('deleted', '==', false))
  const { user } = useAuth()
  const groups = user.isAdmin ? items : items.filter(x => x.members.some(y => y.id === user.id))

  return (
    <FormControl>
      <InputLabel>グループ</InputLabel>
      <Select
        value={value}
        sx={{
          mb: 1,
          minWidth: 120,
          '& .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
        }}
        onChange={e => onChange(e.target.value)}
      >
        <MenuItem value="">すべて</MenuItem>
        <MenuItem value={PERSONAL}>個人</MenuItem>
        {groups.map(group => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
