import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function QueryError({ error }) {
  return (
    <Stack spacing={2}>
      <Typography>クエリの実行に失敗しました。</Typography>
      {error.query && (
        <Box sx={{ bgcolor: '#eee', p: 2 }}>
          <Typography variant="caption" sx={{ 'word-break': 'break-all' }}>
            {error.query}
          </Typography>
        </Box>
      )}
      <Typography>{error.message}</Typography>
    </Stack>
  )
}
