import { useFormContext } from 'react-hook-form'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import { Select, TextField } from 'components/Form'

const DURATION_TYPE = Object.freeze({
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
})

// BigQuery の型に準拠
// https://cloud.google.com/bigquery/docs/reference/standard-sql/datetime_functions#datetime_trunc
const DURATION_UNIT = Object.freeze({
  DAY: 'DAY',
  WEEK: 'WEEK', // 週（日曜始まり）
  ISOWEEK: 'ISOWEEK', // 週（月曜始まり）
})

export const defaultDuration = {
  type: DURATION_TYPE.ABSOLUTE,
  from: dayjs().add(-7, 'day').format('YYYY-MM-DD 00:00'),
  to: dayjs().add(-1, 'day').format('YYYY-MM-DD 23:59'),
}

export default function DurationField({ name }) {
  const { setValue, watch } = useFormContext()
  const type = watch(`${name}.type`)

  const handleChangeType = e => {
    switch (e.target.value) {
      case DURATION_TYPE.ABSOLUTE:
        setValue(`${name}.from`, dayjs().add(-7, 'day').format('YYYY-MM-DD 00:00'))
        setValue(`${name}.to`, dayjs().add(-1, 'day').format('YYYY-MM-DD 23:59'))
        break
      case DURATION_TYPE.RELATIVE:
        setValue(`${name}.from`, 7)
        setValue(`${name}.to`, 1)
        setValue(`${name}.unit`, DURATION_UNIT.DAY)
        break
    }
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography sx={{ fontWeight: 'bold', width: 80 }}>期間</Typography>
        <Select
          label="指定方法"
          name={`${name}.type`}
          defaultValue={DURATION_TYPE.ABSOLUTE}
          onChange={handleChangeType}
        >
          <MenuItem value={DURATION_TYPE.ABSOLUTE}>絶対値</MenuItem>
          <MenuItem value={DURATION_TYPE.RELATIVE}>相対値</MenuItem>
        </Select>
        {type === DURATION_TYPE.ABSOLUTE ? (
          <AbsoluteDurationField name={name} />
        ) : (
          <RelativeDurationField name={name} />
        )}
      </Box>
    </Box>
  )
}

function AbsoluteDurationField({ name }) {
  const { getValues } = useFormContext()
  return (
    <>
      <TextField
        name={`${name}.from`}
        type="datetime-local"
        sx={{ flex: 1 }}
        label="開始日時"
        InputLabelProps={{ shrink: true }}
        rules={{
          validate: {
            message: () => {
              const [_from, _to] = getValues([`${name}.from`, `${name}.to`])
              if (_from === '') {
                return '期間の開始日時は必須項目です'
              }

              const from = dayjs(_from)
              const to = _to !== '' ? dayjs(_to) : dayjs()
              const diff = to.diff(from, 'day')

              if (diff < 0) {
                return '期間の開始・終了日時の指定が逆です'
              }

              if (diff > 365) {
                return '期間は 1 年以内で指定してください'
              }
            },
          },
        }}
      />
      <Typography>〜</Typography>
      <TextField
        name={`${name}.to`}
        type="datetime-local"
        sx={{ flex: 1 }}
        label="終了日時"
        InputLabelProps={{ shrink: true }}
      />
    </>
  )
}

function RelativeDurationField({ name }) {
  const { getValues } = useFormContext()
  return (
    <>
      <TextField
        name={`${name}.from`}
        type="number"
        sx={{ flex: 1 }}
        label="開始日"
        rules={{
          validate: {
            message: () => {
              const [from, _to, unit] = getValues([`${name}.from`, `${name}.to`, `${name}.unit`])
              const to = _to || 0

              if (from === '') {
                return '期間の開始日は必須項目です'
              }

              if (from <= to) {
                return '期間の開始日の値は終了日より大きくする必要があります'
              }

              if (unit === DURATION_UNIT.DAY) {
                if (from - to > 365) {
                  return '期間は 1 年以内で指定してください'
                }
              } else {
                // 365 / 7 = 52.14 なので 53 を許容
                if (from - to > 53) {
                  return '期間は 1 年以内で指定してください'
                }
              }

              return null
            },
          },
        }}
      />
      <Typography>〜</Typography>
      <TextField name={`${name}.to`} type="number" label="終了日" sx={{ flex: 1 }} />
      <Select
        label="単位"
        name={`${name}.unit`}
        defaultValue={DURATION_UNIT.DAY}
        sx={{ minWidth: 180, flex: 1 }}
      >
        <MenuItem value={DURATION_UNIT.DAY}>日前</MenuItem>
        <MenuItem value={DURATION_UNIT.ISOWEEK}>週前（月曜始まり）</MenuItem>
        <MenuItem value={DURATION_UNIT.WEEK}>週前（日曜始まり）</MenuItem>
      </Select>
    </>
  )
}
