import { useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { CONDITION_TYPE, ID_TYPE } from 'entities/segment'

import { Autocomplete, Select } from 'components/Form'
import { filter } from 'components/GroupFilter'
import useSegment from 'hooks/useSegment'
import useAuth from 'lib/auth'

export const defaultSegment = { type: CONDITION_TYPE.SEGMENT, segment: null, operator: 'IN' }

export default function SegmentField({ name }) {
  const { watch } = useFormContext()
  const [filters, idType] = watch(['condition.filters', 'idType'])
  const siblingIds = filters.filter(x => Boolean(x.segment?.id)).map(x => x.segment.id)
  const { user } = useAuth()
  const { items } = useSegment()
  const params = useParams()
  const parentIds = items.filter(x => x.condition.filters.some(y => 'segment' in y)).map(x => x.id)
  const filteredItems = filter(items, '', user)
  const targets = filteredItems
    .filter(x => {
      const segmentIds = x.condition.filters.filter(x => 'segment' in x).map(x => x.segment.id)
      // ID 種別が一致しないセグメントを参照しない
      if (x.idType !== idType) {
        return false
      }
      // 3 階層以上の参照を許可しない（孫セグメントまで許可）
      if (segmentIds.some(x => parentIds.includes(x))) {
        return false
      }
      // 子に自身を許可しない（セグメントのループ回避）
      if (x.id === params.id) {
        return false
      }
      // 孫に自身を許可しない（セグメントのループ回避）
      if (segmentIds.some(x => x === params.id)) {
        return false
      }
      return true
    })
    .map(x => ({ id: x.id, name: x.name, tags: x.tags.join(', ') }))

  return (
    <Box sx={{ flex: 1, display: 'flex', gap: 1 }}>
      <Autocomplete
        name={`${name}.segment`}
        label="作成済みセグメント"
        rules={{ required: true }}
        sx={{ flex: 2 }}
        options={targets || []}
        optionKeys={['id', 'name']}
        renderOptionKeys={['name', 'tags']}
        getOptionDisabled={option => siblingIds.includes(option.id)}
      />
      <Select name={`${name}.operator`} label="演算子" rules={{ required: true }} sx={{ flex: 1 }}>
        <MenuItem value="IN">に含まれる</MenuItem>
        <MenuItem value="NOT IN" disabled={idType !== ID_TYPE.SERIAL.value}>
          に含まれない
        </MenuItem>
      </Select>
    </Box>
  )
}
