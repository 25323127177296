import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import MonacoEditor from '@monaco-editor/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Form, SubmitButton, TextField } from 'components/Form'

export const defaultValues = {
  'text/html': `<html>
<head>
<style>
h1 {
  font-weight: bold;
  color: #003E70;
}
</style>
</head>
<body>
  <h1>タイトル</h1>
  <p>説明文</p>
</body>
</html>`,
  'application/json': `{
  "type": "text",
  "text": "hello!"
}`,
  'text/plain': `text`,
}

export default function EditorDialog({
  defaultValue,
  language,
  disabled = false,
  onChange,
  onClose,
}) {
  const [open, setOpen] = useState(false)
  const form = useForm({ defaultValues: { value: defaultValues[language], ...defaultValue } })
  const value = form.watch('value')

  useEffect(() => {
    setOpen(true)
  }, [])

  const onSubmit = values => {
    onChange(values)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{ onExited: onClose }}
      fullWidth
      maxWidth="xl"
    >
      <Form form={form}>
        <DialogTitle>エディタ</DialogTitle>
        <Stack spacing={2} sx={{ px: 3, py: 2 }}>
          <TextField label="タイトル" name="name" fullWidth />
          <Box sx={{ height: 400, display: 'flex', gap: 2 }}>
            <Box sx={{ flex: 1, border: '1px solid #9e9e9e' }}>
              <Controller
                name="value"
                control={form.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MonacoEditor
                    options={{
                      fontSize: 14,
                      formatOnPaste: true,
                      scrollBeyondLastLine: false,
                      readOnly: disabled,
                    }}
                    defaultValue={defaultValues[language]}
                    language={language}
                    value={field.value}
                    onChange={field.onChange}
                    onMount={editor => {
                      setTimeout(() => {
                        editor.getAction('editor.action.formatDocument').run()
                      }, 50)
                    }}
                  />
                )}
              />
            </Box>
            {language === 'text/html' && (
              <Box
                sx={{
                  flex: 1,
                  border: '1px solid #9e9e9e',
                  overflow: 'scroll',
                  position: 'relative',
                }}
              >
                {Boolean(value) ? (
                  <Preview content={value} />
                ) : (
                  <Box sx={{ p: 2 }}>
                    <Typography color="textSecondary">
                      入力するとプレビューが表示されます
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Stack>
        <DialogActions>
          <Button variant="text" onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <SubmitButton color="inherit" onClick={form.handleSubmit(onSubmit)}>
            保存する
          </SubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

const Preview = ({ content }) => {
  return (
    <iframe
      title="preview"
      className="ql-editor"
      srcDoc={content?.replace(/\n/g, '')}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        padding: 0,
      }}
    />
  )
}
