import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useDoc } from 'lib/firestore'

export default function Maintennace() {
  const { item: maintenance } = useDoc('shared/maintenance')

  const message =
    maintenance?.message || 'ただいまメンテナンス中です。復帰までしばらくお待ち下さい。'

  return (
    <Box>
      <Typography variant="h2" sx={{ mb: 5 }}>
        メンテンナンス中です
      </Typography>
      <Box>
        {message.split('\n').map((x, i) => {
          return (
            <Typography key={i}>
              {x.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? (
                <a href={`mailto:${x}`} target="_blank" rel="noreferrer">
                  {x}
                </a>
              ) : (
                x
              )}
              <br />
            </Typography>
          )
        })}
      </Box>
    </Box>
  )
}
