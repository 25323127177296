import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'

import ListItem from 'components/CommonListItem'
import { confirm } from 'components/Confirm'
import { showSnackbar } from 'components/Snackbar'
import useAuth from 'lib/auth'
import { addDoc, orderBy, updateDoc } from 'lib/firestore'
import { useDocs, where } from 'lib/firestore'

export default function EventTriggerList() {
  const navigate = useNavigate()
  const { items: groups } = useDocs('groups')
  const { user } = useAuth()
  const { items } = useDocs(
    'event-triggers',
    where('deleted', '==', false),
    orderBy('updatedAt', 'desc')
  )

  const handleDuplicate = async item => {
    const data = {
      ...item,
      name: `${item.name} のコピー`,
      createdBy: user.id,
    }
    addDoc('event-triggers', data).catch(() => {
      showSnackbar('イベントトリガーの複製に失敗しました')
    })
  }

  const handleDelete = async item => {
    if (
      await confirm({
        title: 'イベントトリガーを削除してもよろしいですか？',
        body: '一度削除すると復元することはできません。',
      })
    ) {
      updateDoc(`event-triggers/${item.id}`, { deleted: true }).catch(() =>
        showSnackbar('イベントトリガーの削除に失敗しました')
      )
    }
  }

  return (
    <Box>
      <Box>
        {items.map(x => (
          <ListItem
            key={x.id}
            item={x}
            group={groups.find(y => y.id === x.group)}
            editable={!x.group || user.groups?.includes(x.group)}
            onClick={() => navigate(`./${x.id}`)}
            onDelete={() => handleDelete(x)}
            onDuplicate={() => handleDuplicate(x)}
          />
        ))}
      </Box>
      <Fab onClick={() => navigate('./new')}>
        <AddIcon />
      </Fab>
    </Box>
  )
}
