import React, { createContext, useContext } from 'react'

import { useDoc } from 'lib/firestore'

const SharedDataContext = createContext()

export function SharedDataProvider({ children }) {
  const { item } = useDoc('shared/data')

  return <SharedDataContext.Provider value={item || {}} children={children} />
}

export default function useSharedData() {
  return useContext(SharedDataContext)
}
