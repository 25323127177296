import { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

export default function NotificationListDialog({ items, onClose }) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} TransitionProps={{ onExited: onClose }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        お知らせ
        <Box sx={{ flex: 1 }} />
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box sx={{ pb: 1 }}>
        {items.map(x => (
          <Box key={x.id} sx={{ py: 1.5, px: 2 }}>
            <Typography variant="caption" color="text.secondary" component="p" sx={{ mb: 0.5 }}>
              {dayjs(x.publishedAt).format('YYYY.MM.DD HH:mm')}
            </Typography>
            <Typography>
              {x.text.split('\n').map((text, i) => (
                <span key={i}>
                  {text}
                  <br />
                </span>
              ))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}
