import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useLocation } from 'react-router-dom'

import AccountTreeIcon from '@mui/icons-material/AccountTreeTwoTone'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import GroupsIcon from '@mui/icons-material/GroupsTwoTone'
import LabelIcon from '@mui/icons-material/LabelTwoTone'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone'
import PersonIcon from '@mui/icons-material/PersonTwoTone'
import ScienceIcon from '@mui/icons-material/ScienceTwoTone'
import SegmentIcon from '@mui/icons-material/Segment'
import SendIcon from '@mui/icons-material/SendTwoTone'
import SettingsIcon from '@mui/icons-material/SettingsTwoTone'
import VpnLockIcon from '@mui/icons-material/VpnLockTwoTone'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import MuiAppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Popover from '@mui/material/Popover'
import { styled, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import Confirm from 'components/Confirm'
import ErrorDialog from 'components/ErrorDialog'
import MenuListItem from 'components/MenuListItem'
import NotificationListDialog from 'components/Notification/ListDialog'
import Prompt from 'components/Prompt'
import Snackbar from 'components/Snackbar'
import useAuth, { signOut } from 'lib/auth'
import { limit, orderBy, useDoc, useDocs, where } from 'lib/firestore'

const userMenus = [
  {
    label: 'セグメント',
    to: 'segments',
    icon: SegmentIcon,
  },
  {
    label: 'ユーザーラベル',
    to: 'user-labels',
    icon: LabelIcon,
  },
  {
    label: 'メール配信',
    to: 'target-mails',
    icon: SendIcon,
  },
  {
    label: '施策',
    to: 'activations',
    icon: ScienceIcon,
  },
  {
    label: 'グループ',
    to: 'groups',
    icon: GroupsIcon,
  },
]

const adminMenus = [
  {
    label: 'ユーザー',
    to: 'users',
    icon: PersonIcon,
  },
  {
    label: 'イベントトリガー',
    to: 'event-triggers',
    icon: AccountTreeIcon,
  },
  {
    label: 'お知らせ',
    to: 'notifications',
    icon: NotificationsIcon,
  },
  {
    label: 'IP ホワイトリスト',
    to: 'ips',
    icon: VpnLockIcon,
  },
  {
    label: '各種設定',
    to: 'settings',
    icon: SettingsIcon,
  },
]

const drawerWidth = 280

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  width: `calc(100% - ${drawerWidth}px)`,
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

const KEY_NOTIFICATION = 'NOTIFICATION'
const KEY_OPEN_MENU = 'OPEN_MENU'
const storage = window.localStorage
const date = new Date()

export default function Layout() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(storage.getItem(KEY_OPEN_MENU) === 'true')
  const [openNotification, setOpenNotification] = useState(false)
  const { user } = useAuth()
  const [hasUnread, setHasUnread] = useState(false)
  const { items } = useDocs(
    'notifications',
    where('deleted', '==', false),
    where('publishedAt', '<=', date),
    orderBy('publishedAt', 'desc'),
    limit(5)
  )
  const { item: maintenance } = useDoc('shared/maintenance')
  const theme = useTheme()
  const location = useLocation()
  const title = useMemo(() => {
    const currentMenu = [...userMenus, ...adminMenus].find(menu => location.pathname.match(menu.to))
    return currentMenu?.label || ''
  }, [location])

  useEffect(() => {
    if (items.length === 0) return
    const target = items[0]
    const time = Number(storage.getItem(KEY_NOTIFICATION))
    if (!time || target.publishedAt.getTime() > time) {
      setHasUnread(true)
    }
  }, [items])

  const handleClickNotification = () => {
    setOpenNotification(true)
    setHasUnread(false)
    storage.setItem(KEY_NOTIFICATION, new Date().getTime())
  }

  const handleOpen = value => {
    setOpen(value)
    storage.setItem(KEY_OPEN_MENU, value)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CssBaseline />
      <AppBar position="fixed" color="inherit" open={open} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => handleOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flex: 1 }}>
            {title}
          </Typography>
          <IconButton onClick={handleClickNotification}>
            <Badge variant="dot" invisible={!hasUnread}>
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={() => handleOpen(false)}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {userMenus.map(x => (
            <MenuListItem key={x.to} to={x.to} Icon={x.icon}>
              {x.label}
            </MenuListItem>
          ))}
        </List>
        <Divider />
        {user.isAdmin && (
          <List>
            <ListSubheader sx={{ background: 'none' }}>管理者メニュー</ListSubheader>
            {adminMenus.map(x => (
              <MenuListItem key={x.to} to={x.to} Icon={x.icon}>
                {x.label}
              </MenuListItem>
            ))}
          </List>
        )}
        <Box sx={{ flex: 1 }} />
        <List sx={{ mb: 2 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={event => setAnchorEl(event.currentTarget)}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="設定" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        marginThreshold={1}
      >
        <List>
          <ListItem>
            <ListItemText secondary={user.email} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={signOut}>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
      <Main open={open}>
        <DrawerHeader />
        {maintenance?.isMaintenance && (
          <Box
            sx={{
              mt: -3,
              mx: -3,
              mb: 3,
              height: 48,
              backgroundColor: '#ed6C02',
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
            }}
          >
            <WarningAmberRoundedIcon sx={{ mx: 2 }} />
            <Typography sx={{ color: '#fff', fontSize: 16 }}>
              メンテナンス中です。管理者は各種設定メニューからメンテナンスモードを解除できます。
            </Typography>
          </Box>
        )}
        <Outlet />
      </Main>
      <Snackbar />
      <Confirm />
      <ErrorDialog />
      <Prompt />
      {openNotification && (
        <NotificationListDialog items={items || []} onClose={() => setOpenNotification(false)} />
      )}
    </Box>
  )
}
