import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import RefreshIcon from '@mui/icons-material/Refresh'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'

import Chart from 'components/Chart'
import { showError } from 'components/ErrorDialog'
import { Autocomplete } from 'components/Form'
import useJob, { JOB_MODE } from 'hooks/useJob'
import useSharedData from 'hooks/useSharedData'
import { numberWithCammas } from 'utils'

import QueryError from './QueryError'

const colors = [
  '#003e70',
  '#0061B4',
  '#009bff',
  '#6dcdff',
  '#dcf0ff',
  '#f5f5f5',
  '#e0e0e0',
  '#999999',
  '#616161',
  '#212121',
]

export default function SegmentPreview({ latestResult, count, onReload }) {
  const sharedData = useSharedData()
  const { handleSubmit, formState, watch } = useFormContext()
  const { job, running, registerJob } = useJob()
  const idType = watch('idType')
  const hasPreviewResult = typeof count === 'number'

  const datasets = useMemo(() => {
    return (job?.result?.breakdown || []).map((x, i) => ({
      label: x.field !== '' ? x.field : '""',
      data: [x.count],
      backgroundColor: colors[Math.min(i, colors.length - 1)],
    }))
  }, [job])

  const options = useMemo(() => {
    return sharedData.mainTable?.schema.fields.filter(x =>
      sharedData.breakdownFields.includes(x.name)
    )
  }, [sharedData])

  // FIXME: 実行部分修正。setTimeout なしで解決
  const handleChange = values => {
    if (!values.breakdown || values.breakdown.name === job?.segment.breakdown?.name) {
      return
    }
    return registerJob({ segment: values, mode: JOB_MODE.BREAKDOWN }).catch(error => {
      showError({ message: <QueryError error={error} /> })
    })
  }

  if (!sharedData.mainTable || (!hasPreviewResult && !latestResult)) {
    return null
  }

  return (
    <Box sx={{ width: 280 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderLeft: theme => `1px solid ${theme.palette.divider}`,
          position: 'fixed',
          top: 64,
          bottom: 0,
          px: 3,
          py: 2,
          width: 280,
        }}
      >
        {hasPreviewResult && (
          <Box sx={{ mb: 3 }}>
            <Typography color="textSecondary" sx={{ mb: 0.5 }}>
              現在のユーザー数
            </Typography>
            <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'baseline' }}>
              {formState.isDirty &&
                Boolean(formState.dirtyFields.condition) &&
                !(running || formState.isSubmitting) && (
                  <IconButton sx={{ p: 0, mr: 1 }} onClick={onReload}>
                    <RefreshIcon />
                  </IconButton>
                )}
              <Typography sx={{ fontSize: 32, mr: 1 }}>{numberWithCammas(count)}</Typography>
              <Typography sx={{ fontSize: 18 }}>人</Typography>
            </Box>
            {idType === 'serial' && (
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography color="textSecondary" sx={{ fontSize: 18, mr: 0.25 }}>
                  {parseInt((count / sharedData.mainTable.numRows) * 10000) / 100}
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 13, mr: 1 }}>
                  %
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 12, mr: 0.5 }}>
                  全ユーザー:
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 12 }}>
                  {numberWithCammas(sharedData.mainTable.numRows)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {Boolean(latestResult) && (
          <Box>
            <Typography color="textSecondary" sx={{ mb: 0.5 }}>
              最終実行時のユーザー数
            </Typography>
            <Box sx={{ mb: 0.5, display: 'flex', alignItems: 'baseline' }}>
              <Typography sx={{ fontSize: 32, mr: 1 }}>
                {numberWithCammas(Number(latestResult.numRows))}
              </Typography>
              <Typography sx={{ fontSize: 18 }}>人</Typography>
            </Box>
            <Box sx={{ mb: 3, display: 'flex', alignItems: 'baseline' }}>
              <Typography color="textSecondary" sx={{ fontSize: 12 }}>
                {`実行日時 ${dayjs(new Date(Number(latestResult.creationTime))).format(
                  'YYYY-MM-DD HH:mm'
                )}`}
              </Typography>
            </Box>
          </Box>
        )}
        {idType === 'serial' && hasPreviewResult && (
          <Autocomplete
            label="内訳"
            name="breakdown"
            sx={{ mb: 3 }}
            fullWidth
            disabled={running || formState.isSubmitting}
            onChange={() => setTimeout(handleSubmit(handleChange), 10)}
            options={options}
            optionKeys={['name', 'description']}
            disableClearable
            popupIcon={
              job?.mode === 'BREAKDOWN' && (running || formState.isSubmitting) ? (
                <CircularProgress sx={{ mt: '3px', mr: '3px' }} size={18} />
              ) : (
                <ArrowDropDownIcon />
              )
            }
          />
        )}
        <Box sx={{ minHeight: 240 }}>
          {job?.result?.breakdown?.length > 0 && (
            <Chart
              data={{
                type: 'bar',
                data: { datasets: [...datasets].reverse(), labels: [''] },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: { legend: { display: false } },
                  scales: {
                    x: { stacked: true },
                    y: { stacked: true },
                  },
                },
              }}
            />
          )}
        </Box>
        <Box sx={{ flex: 1, overflow: 'scroll' }}>
          {datasets.map((x, i) => (
            <Tooltip key={i} title={`${x.label}: ${numberWithCammas(x.data[0])}`} placement="top">
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mr: 0.5 }}>
                <Box sx={{ width: 20, height: 6, background: x.backgroundColor, mr: '3px' }} />
                <Typography sx={{ fontSize: 6, color: 'text.secondary' }}>{x.label}</Typography>
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
