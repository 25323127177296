import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'

import { Select, Switch } from 'components/Form'

import FilterField, { defaultFilter } from './FilterField'
import ManualInputField, { defaultManualInputValue } from './ManualInputField'

export default function TableFieldArray({ name, table, canAppendGroup = true, group = false }) {
  const [appendTarget, setAppendTarget] = useState(null)
  const [editTarget, setEditTarget] = useState(null)
  const { control, watch } = useFormContext()
  const { fields, append, insert, remove, update } = useFieldArray({
    control,
    name: `${name}.filters`,
  })
  const filters = watch(`${name}.filters`)

  const handleGroup = () => {
    update(editTarget.index, { operator: 'AND', filters: [filters[editTarget.index]] })
    setEditTarget(null)
  }

  const handleDuplicate = () => {
    insert(editTarget.index + 1, filters[editTarget.index])
    setEditTarget(null)
  }

  const handleRemove = index => {
    if (filters.length > 1) {
      remove(index)
    }
    setEditTarget(null)
  }

  const handleAppendFilter = () => {
    append(defaultFilter)
    if (appendTarget) {
      setAppendTarget(null)
    }
  }

  const handleAppendManualInput = () => {
    append(defaultManualInputValue)
    if (appendTarget) {
      setAppendTarget(null)
    }
  }

  return (
    <>
      {fields.map((item, index) => (
        <Box key={item.id} sx={{ display: 'flex', alignItems: 'start', gap: 1, mb: 2 }}>
          <Box sx={{ width: 80, height: 48, display: 'flex', alignItems: 'center' }}>
            {index === 0 ? (
              <Typography sx={{ fontWeight: 'bold' }}>条件</Typography>
            ) : (
              <Select
                name={`${name}.operator`}
                defaultValue="AND"
                disabled={index > 1}
                sx={{
                  width: 80,
                  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
                }}
              >
                <MenuItem value="AND">AND</MenuItem>
                <MenuItem value="OR">OR</MenuItem>
              </Select>
            )}
          </Box>
          <Field name={`${name}.filters.${index}`} filter={filters[index]} table={table} />
          <IconButton
            onClick={e => setEditTarget({ index, element: e.currentTarget })}
            sx={{ mt: '4px' }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      ))}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="text"
          startIcon={<AddIcon />}
          onClick={e => setAppendTarget(e.currentTarget)}
        >
          条件を追加
        </Button>
        {group && (
          <>
            <Box sx={{ flex: 1 }} />
            <Switch name={`${name}.not`} label="条件を反転（NOTにする）" />
          </>
        )}
        <Popover
          open={Boolean(appendTarget)}
          anchorEl={appendTarget}
          onClose={() => setAppendTarget(null)}
        >
          <List sx={{ minWidth: 80 }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleAppendFilter()}>
                <ListItemText primary="条件追加" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleAppendManualInput()}>
                <ListItemText primary="手入力で条件追加" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Box>
      <Popover
        open={Boolean(editTarget)}
        anchorEl={Boolean(editTarget) && editTarget.element}
        onClose={() => setEditTarget(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 80 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleDuplicate()}>
              <ListItemText primary="複製" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              disabled={filters.length <= 1}
              onClick={() => handleRemove(editTarget.index)}
            >
              <ListItemText primary="削除" />
            </ListItemButton>
          </ListItem>
          {canAppendGroup && (
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGroup()}>
                <ListItemText primary="グループ化" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  )
}

const Field = ({ name, filter, table }) => {
  if ('column' in filter) {
    return <FilterField name={name} table={table} />
  }

  if ('text' in filter) {
    return <ManualInputField name={name} />
  }

  if ('filters' in filter) {
    return (
      <Box
        sx={{
          flex: 1,
          p: 2,
          border: theme => `1px solid ${theme.palette.grey[500]}`,
          borderRadius: 2,
          background: theme => theme.palette.grey[300],
        }}
      >
        <TableFieldArray name={name} canAppendGroup={false} table={table} group={true} />
      </Box>
    )
  }

  return null
}
