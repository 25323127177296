import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const key = 'confirm'

export default function Confirm() {
  const [state, setState] = useState(null)
  const { message, resolve } = state || {}

  useEffect(() => {
    const handleChange = e => setState(e.detail)
    window.addEventListener(key, handleChange)
    return () => window.removeEventListener(key, handleChange)
  }, [])

  const handleConfirm = () => {
    resolve(true)
    setState(null)
  }

  const handleCancel = () => {
    resolve(false)
    setState(null)
  }

  return (
    <Dialog
      open={Boolean(message)}
      onClose={handleCancel}
      TransitionProps={{ onExited: () => setState(null) }}
    >
      <DialogTitle>{message?.title}</DialogTitle>
      <DialogContent>{message?.body}</DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button variant="text" onClick={handleConfirm}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const confirm = message => {
  return new Promise(resolve => {
    const event = new CustomEvent(key, { detail: { message, resolve } })
    window.dispatchEvent(event)
  })
}
