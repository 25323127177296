import { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

const key = 'error'

export default function ErrorDialog(props) {
  const [error, setError] = useState(null)

  useEffect(() => {
    const handleChange = event => {
      setError(event.detail)
    }
    window.addEventListener(key, handleChange)
    return () => window.removeEventListener(key, handleChange)
  }, [])

  return (
    <Dialog open={Boolean(error)} onClose={() => setError(null)}>
      <DialogTitle>{error?.title || 'エラー'}</DialogTitle>
      <DialogContent>{error?.message}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setError(null)
            if (error.onClick) {
              error.onClick()
            }
          }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const showError = error => {
  const event = new CustomEvent(key, { detail: error })
  window.dispatchEvent(event)
}
