import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'

import { showError } from 'components/ErrorDialog'
import { showSnackbar } from 'components/Snackbar'
import { getDocs, limit, updateDoc, useDocs, where } from 'lib/firestore'

import GroupListItem from './ListItem'

export default function GroupList() {
  const navigate = useNavigate()
  const { items } = useDocs('groups', where('deleted', '==', false))

  const handleDelete = async item => {
    for await (const collection of ['activations', 'event-triggers', 'segments', 'target-mails']) {
      const data = await getDocs(collection, where('group', '==', item.id), limit(1))
      if (data.length > 0) {
        showError({
          message:
            '一度データが作成されたグループは削除できません。削除する必要がある場合には管理者に問い合わせて下さい。',
        })
        return
      }
    }

    updateDoc(`groups/${item.id}`, { deleted: true })
      .then(() => showSnackbar('グループを削除しました'))
      .catch(() => showSnackbar('グループの削除に失敗しました'))
  }

  return (
    <Box>
      {items.map(x => (
        <GroupListItem
          key={x.id}
          item={x}
          onClick={() => navigate(`./${x.id}`)}
          onDelete={() => handleDelete(x)}
        />
      ))}
      <Fab onClick={() => navigate('./new')}>
        <AddIcon />
      </Fab>
    </Box>
  )
}
