import { useFieldArray, useFormContext } from 'react-hook-form'

import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Select } from 'components/Form'

import AddButton from './AddButton'
import ConditionField, { defaultCondition } from './ConditionField'
import MoreVertButton from './MoreVertButton'

export const defaultGroup = { operator: 'must', conditions: [defaultCondition] }

export default function GroupField({ name }) {
  const { control, watch } = useFormContext()
  const { fields, insert, append, remove, update } = useFieldArray({
    control,
    name: `${name}.conditions`,
  })
  const conditions = watch(`${name}.conditions`)

  return (
    <Stack
      spacing={2}
      sx={
        name === 'conditions'
          ? {}
          : {
              p: 2,
              border: theme => `1px solid ${theme.palette.grey[500]}`,
              borderRadius: 2,
              background: theme => theme.palette.grey[200],
            }
      }
    >
      {fields.map((item, index) => (
        <Box key={item.id} sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ width: 88, height: 54, display: 'flex', alignItems: 'center' }}>
            {index === 0 ? (
              <Typography sx={{ fontWeight: 'bold' }}>条件</Typography>
            ) : (
              <Select
                name={`${name}.operator`}
                disabled={index > 1}
                sx={{
                  width: 80,
                  '& .Mui-disabled .MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
                }}
              >
                <MenuItem value="must">AND</MenuItem>
                <MenuItem value="should">OR</MenuItem>
              </Select>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            {Boolean(item.conditions) ? (
              <GroupField name={`${name}.conditions.${index}`} />
            ) : (
              <ConditionField name={`${name}.conditions.${index}`} onDelete={() => remove(index)} />
            )}
          </Box>
          <Box>
            <MoreVertButton
              options={[
                { label: '複製', onClick: () => insert(index + 1, conditions[index]) },
                { label: '削除', onClick: () => remove(index) },
                {
                  label: 'グループ化',
                  onClick: () =>
                    update(index, { ...defaultGroup, conditions: [conditions[index]] }),
                },
              ]}
            />
          </Box>
        </Box>
      ))}
      <Box>
        <AddButton
          options={[
            { label: '条件を追加', onClick: () => append(defaultCondition) },
            { label: 'グループを追加', onClick: () => append(defaultGroup) },
          ]}
        >
          条件追加
        </AddButton>
      </Box>
    </Stack>
  )
}
