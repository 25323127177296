import { Autocomplete } from 'components/Form'
import { filter } from 'components/GroupFilter'
import useAuth from 'lib/auth'
import { useDocs, where } from 'lib/firestore'

export default function UserLabelField({ name }) {
  const { user } = useAuth()
  const { items } = useDocs('user-labels', where('deleted', '==', false))
  const filteredItems = filter(items, '', user).map(({ id, name }) => ({ id, name }))

  return (
    <Autocomplete
      name={name}
      label="ユーザーラベル"
      fullWidth
      InputLabelProps={{ shrink: true }}
      placeholder="指定しない"
      options={filteredItems}
      optionKeys={['id', 'name']}
      renderOptionKeys={['name', 'id']}
    />
  )
}
