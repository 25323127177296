export const USER_STATUS = Object.freeze({
  ADMIN: 'admin',
  USER: 'user',
  PENDING: 'pending',
  DELETED: 'deleted',
})

export const USER_STATUS_LABEL = Object.freeze({
  [USER_STATUS.ADMIN]: '管理者',
  [USER_STATUS.USER]: 'ユーザー',
  [USER_STATUS.PENDING]: '承認待ち',
  [USER_STATUS.DELETED]: '削除済み',
})
