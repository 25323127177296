import { useState } from 'react'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'

export default function MoreVertButton({ options }) {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <IconButton onClick={e => setAnchorEl(e.currentTarget)} sx={{ mt: '4px' }}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ minWidth: 80 }}>
          {options.map((x, i) => (
            <ListItem key={i} disablePadding>
              <ListItemButton
                onClick={() => {
                  x.onClick()
                  setAnchorEl(null)
                }}
              >
                <ListItemText primary={x.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  )
}
