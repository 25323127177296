import { Outlet } from 'react-router-dom'

import Box from '@mui/material/Box'

import ErrorDialog from 'components/ErrorDialog'
import { ReactComponent as LoginImage } from 'images/login.svg'

export default function AuthLayout() {
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box
        sx={{
          width: 480,
          bgcolor: 'primary.main',
          display: { xs: 'none', sm: 'none', md: 'flex' },
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoginImage />
      </Box>
      <Box sx={{ ml: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Outlet />
      </Box>
      <ErrorDialog />
    </Box>
  )
}
